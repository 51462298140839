a {
  text-decoration: none;
  color: $color-links;

  &:hover,
  &:focus,
  &:active {
    color: $color-links--hover;
  }

  .page--premium & {
    color: $gold;

    &:hover,
    &:focus,
    &:active {
      color: $gold--dark;
    }
  }
}

.link--underline {
  text-decoration: underline;
}

.link--alt {
  color: $color-links--alt;

  &:hover,
  &:focus,
  &:active {
    color: $color-links--alt--hover;
  }
}

.link--light {
  color: $green--lighter;

  &:hover,
  &:focus,
  &:active {
    color: $green--light;
  }
}

.link--premium {
  color: $gold;

  &:hover,
  &:focus,
  &:active {
    color: $gold--dark;
  }
}

a,
button,
.button,
input[type="submit"] {
  transition: color $transition-duration--short, background $transition-duration--short, border $transition-duration--short;
}
