.spa-gifts\:delivery-cards .ui-component-card {
  &:nth-child(1) [data-img] {
    position: relative;
    @extend .utils\:loop;
    @extend .loop\:top;
    @extend .loop\:sm;
  }

  &:nth-child(3) [data-img] {
    position: relative;
    @extend .utils\:swirl-1;
    @extend .swirl-1\:bottom;
    @extend .swirl-1\:xs;
  }
}
