fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

ul, ol {
  + ul, + ol {
    margin-bottom: 0;
  }
}
