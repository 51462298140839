.summary {
  background-color: $grey--lightest;

  .summary-item {
    @extend .wrapper;

    padding-top: em($spacing-unit--tiny);
    padding-bottom: em($spacing-unit--tiny);
    border-bottom: 1px solid $grey--lighter;
    line-height: 1.5em;
    display: table;
    width: 100%;
    min-height: 55px;

    &__icon,
    &__header,
    &__link {
      display: table-cell;
      vertical-align: middle;
    }

    &__icon {
      text-align: left;
      width: em(28px);
    }

    &__link {
      text-align: right;
      cursor: pointer;
      color: $green;

      .theme\:premium & {
        color: $gold;
      }
    }

    &__title {
      font-weight: bold;
    }

    &__subtitle {
      opacity: .8;
      font-style: italic;
      font-size: 90%;
    }
  }
}

.summary--checkout {
  background-color: $color-bg;

  .summary-item__title {
    font-size: sb-rem($base-font-size * 1.15);
  }
}
