@use "sass:math";

@import "../../ui_components/helpers/fontawesome"; // $fa-ul-margin-left

// Note: If any child elements within the block this mixin is applied to are
// overflowed, that could cause the block's `scrollHeight` to be greater than
// its `clientHeight` even if there's no overflow, resulting in false positives
// from the `isTextOverflowed` function. Absolutely positioned items within the
// block could cause similar issues as well. Ensure all such items have
// `overflow: hidden` applied to avoid this.
@mixin truncate-text($font-size: $base-font-size, $lines: 1, $fade: false, $fade-color: #fff) {
  $line-height-factor: math.div($base-line-height, $font-size);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @if $lines > 1 {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }

  @if $fade {
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      height: sb-rem($spacing-unit--large);
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: sb-rem(-$spacing-unit--small);
      box-shadow: inset 0 -1.25rem 1.5rem $fade-color;
    }
  }
}

@mixin truncate-list($font-size: $base-font-size, $line-height: $base-line-height, $padding: 0, $lines: 1) {
  $line-height-factor: math.div($line-height, $font-size);

  overflow: hidden;
  height: (math.div($font-size, $base-font-size) * 1rem * $line-height-factor + sb-rem($padding) * 2) * $lines;

  // If ever we want to truncate `.fa-ul` using this mixin then
  // `overflow: hidden` will cause the absolutely positioned `.fa-li` items to
  // be hidden. Override FA to use `padding-left` instead of `margin-left` to
  // avoid this.
  &.fa-ul {
    margin-left: 0 !important;
    padding-left: $fa-ul-margin-left !important;
  }
}
