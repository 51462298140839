 // Create a striped background from a single colour
 // Based on: http://lea.verou.me/css3patterns/#diagonal-stripes
@mixin striped-bg($base-color, $width, $angle, $contrast: .3) {
  background-color: $base-color;
  background-image: repeating-linear-gradient(
    $angle,
    transparent,
    transparent $width,
    rgba(255,255,255,$contrast) $width,
    rgba(255,255,255,$contrast) 2 * $width
  );
}

@mixin circle-bg($min-width, $bg-color) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: $min-width;
  border-radius: 50%;
  background-color: $bg-color;

  &::after {
    display: table;
    content: "";
    padding-top: 100%;
  }
}
