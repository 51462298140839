@use "sass:math";

@import "config/config";
@import "helpers/wrapper";

/**
 * Markup:
 * <article class="ui-component-article
 *                 align:{center*, left}">
 *
 *   <header></header>
 *   <section>
 *     <a class="ui-component-button"></a>
 *   </section>
 * </article>
 */
.ui-component-article {
  text-align: center;

  &.align\:left {
    text-align: left;

    > header, > section:not(.ui-component-section) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  > header, > section:not(.ui-component-section) {
    margin-bottom: $space-xl;
    @include wrapper;

    &:last-child {
      &, & > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul, ol {
    margin-bottom: $space;

    &:not(.variant\:bare) {
      text-align: left;
    }
  }

  // Consistent font sizes for banners and section-level articles
  .ui-component-section > &,
  .ui-component-banner > & {
    > header {
      > :first-child {
        font-size: $base-font * math.pow($scale, 2);
      }

      > :nth-child(2):not(p):not(a),
      > p {
        font-size: $base-font * $scale;
      }
    }
  }

  .ui-component-section.variant\:hero > &,
  .ui-component-banner.variant\:hero > & {
    > header {
      > :first-child {
        font-size: $base-font * math.pow($scale, 3);
        font-weight: 700;
      }

      > :nth-child(2):not(p):not(a),
      > p {
        font-size: $base-font * math.pow($scale, 2);
      }
    }
  }
}
