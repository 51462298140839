@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes zeroDimensions {
  0% {
    height: 100%;
    width: 100%;
  }

  100% {
    height: 0;
    width: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(100vw);
  }

  70% {
    transform: translateX(calc(100vw - 102%));
  }

  100% {
    transform: translateX(calc(100vw - 100%));
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(calc(100vw - 100%));
  }

  30% {
    transform: translateX(calc(100vw - 102%));
  }

  100% {
    transform: translateX(100vw);
  }
}

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
  }

  70% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes fadeInUpGrow {
  from {
    opacity: 0;
    transform: translateY(10%) scale(.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeOutDownShrink {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  to {
    opacity: 0;
    transform: translateY(10%) scale(.9);
  }
}
