.consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem;
  background-color: rgba(#222, .875);
  color: #fff;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  a, button {
    color: #fff !important;
  }

  p {
    margin: 0;
  }
}

.consent-banner__content,
.consent-banner__controls {
  display: inherit;
  flex-direction: column;
  justify-content: inherit;
}

.consent-banner__content {
  padding: 0 0 .5rem;
}

.consent-banner__controls {
  align-items: inherit;
  padding: 0 .5rem;
}
