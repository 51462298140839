.offer--bumper {
  position: relative;
  max-height: 500px;
  text-align: center;

  body.--map-open & {
    margin: 0;

    @include container-style("--screen-desktop-search", true) {
      margin: var(--space) 0;
    }
  }

  @include media(tab-and-up) {
    text-align: left;
  }

  .offer__ctas {
    text-align: left;
  }

  .offer__cta-button-group {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      align-items: baseline;

      .far {
        flex-shrink: 0;
        margin-right: sb-rem($spacing-unit--tiny);
      }
    }
  }

  .offer__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 0 var(--space);
    text-align: center;
    color: inherit;
    border: none !important;

    .h3, .bumper__icon {
      margin: sb-rem($spacing-unit--small) 0;
    }

    .bumper__icon {
      transform: scaleX(-1);
    }
  }

  .offer__container {
    justify-content: center;
    align-items: center;
  }
}
