@use "sass:math";

@import "../config/config";
@import "../helpers/units";

h1, .h1,
h2, .h2 {
  font-weight: 400;
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
ul, ol {
  margin-top: 0;
  margin-bottom: $baseline;
  line-height: #{strip-unit($baseline)};
}

@for $i from 1 through 6 {
  h#{$i}, .h#{$i} {
    font-size: $base-font * math.pow($scale, 4 - $i);
  }
}

header p,
p.lead,
p.lede {
  font-size: $base-font * $scale;
}
