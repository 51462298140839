.page--elysium-collection {
  .far {
    color: $gold;
  }

  hr {
    margin: sb-rem($spacing-unit) 0;
  }

  .hero-banner--divider {
    background-color: #fff;
    padding-top: sb-rem($spacing-unit);
    padding-bottom: sb-rem($spacing-unit);

    .hero-banner__content {
      h1, h2 {
        font-weight: 100;
      }
    }
  }

  .hero-banner--divider,
  .section__heading {
    i {
      padding-bottom: sb-rem($spacing-unit);
    }
  }

  .section__heading {
    text-align: center;
  }

  .section__heading,
  .section__content p {
    @include media-query("tab-and-up") {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .list--columns {
    ul {
      columns: 3 320px;
    }
  }

  .contact-bar {
    text-align: center;

    h2, .h2 {
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .offer:not(.offer--full-width) .offer__title {
    @include truncate-text($lines: 2);
  }
}
