@use "sass:math";

$video--aspect-ratio: math.div(16, 9);

/**
 * Markup:
 * <div class="ui-component-video">
 *   <iframe></iframe>
 * </div>
 */
.ui-component-video {
  position: relative;
  height: 0;
  padding-bottom: percentage(math.div(1, $video--aspect-ratio));

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
