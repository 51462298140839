@use "sass:math";

@import "config/config";
@import "helpers/utilities";

/**
 * Markup:
 * <div class="ui-component-from-price
 *             size:{sm, md*, lg}
 *             variant:{stacked}">
 *   <span>from <span class="original">£100</span></span> // optional
 *   <span class="price">£99</span> // or "Call for pricing"
 *   <span>per person</span> // optional
 * </div>
 */
.ui-component-from-price {
  line-height: math.div($baseline, $scale);
  text-align: right;
  white-space: nowrap;

  > .price {
    font-weight: 700;
    font-size: $base-font * $scale;
  }

  > :not(.price) {
    @include text-small;
    @include text-muted;

    &:last-child {
      display: block;
    }
  }

  > span > .original {
    text-decoration: line-through;
  }

  &.size\:lg > .price {
    font-size: $base-font * 1.539; // $scale ^ 3/2
  }

  &.size\:sm > .price {
    font-size: $base-font;
  }

  &.variant\:stacked {
    display: flex;
    flex-direction: column;
  }

  &.variant\:slash {
    text-align: left;

    & > .price {
      font-weight: 400;
    }
  }
}
