@use "sass:math";

@import "../config/config";

// `$offset` corresponds to values of any negative margins set on image container. E.g.
// if image container has `margin: 0 -$space` then `$offset: $space`.
@mixin image-aspect-ratio($ratio, $offset: 0rem) {
  @if $ratio > 0 {
    aspect-ratio: $ratio;
    object-fit: cover;
    width: calc(100% + 2 * #{$offset});

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  } @else {
    &, & img {
      aspect-ratio: initial;
      width: 100%;
    }
  }
}
