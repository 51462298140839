@use "sass:color";

@import "config/config";
@import "helpers/media-queries";

/**
 * Markup:
 * <aside
 *   class="ui-component-snackbar align:{center*, start}"
 *   data-snackbar
 * >
 *   <section data-active="{true, false}"> // set via JS
 *     <div>
 *       <i class="far fa-fw fa-icon" aria-hidden="true"></i> // optional icon
 *       <span>Message here</span>
 *       <button data-dismiss class="ui-component-button variant:dismiss"></button> // optional dismiss button
 *     </div>
 *   </section>
 *   // optional further messages here
 * </aside>
 */
.ui-component-snackbar {
  --animation-timeout: 0.25s;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: $z-index-snackbar;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: var(--space);
  width: 100%;

  > section {
    --offset: var(--space);
    position: relative;
    left: 50%;
    bottom: var(--offset);
    width: clamp(320px, 100vw - var(--offset), 480px);
    padding: var(--space-sm);
    background: $grey-400;
    border-radius: 3px;
    transform: translate(-50%, calc(100% + var(--offset)));
    opacity: 0;
    transition: transform var(--animation-timeout) 0ms cubic-bezier(0.4, 0, 1, 1), opacity var(--animation-timeout) ease-in-out;
    will-change: transform;

    &[data-active="true"] {
      transform: translate(-50%, 0);
      opacity: 1;
      transition: transform var(--animation-timeout) 0ms cubic-bezier(0, 0, 0.2, 1);
      pointer-events: auto;
    }

    > div {
      display: flex;
      align-items: center;
      gap: var(--space-xs);
      width: 100%;
      color: #fff;

      > button {
        margin-left: auto;
      }
    }
  }

  &.align\:start > section {
    left: var(--offset);
    transform: translate(0, calc(100% + var(--offset)));

    &[data-active="true"] {
      transform: translate(0);
    }
  }

  i[aria-hidden="true"] {
    color: $sb-green-200;
  }

  a, button.variant\:dismiss, button.variant\:link {
    color: $sb-green-200;

    &:hover, &:focus, &:active {
      color: color.adjust($sb-green-200, $lightness: 5%);
    }
  }

  a {
    text-decoration: underline;
  }
}
