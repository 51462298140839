@import "config/config";
@import "helpers/media-queries";
@import "helpers/wrapper";

/**
  * Markup:
  * <div class="ui-component-status-bar
  *             variant:{booking, calendar, venue}
  *             is-sticky // toggle via JS to show/hide box-shadow
  *             is-hidden"> // toggle via JS to slide offscreen
  *   <section>
  *     <header></header>
  *     <button class="ui-component-button"></button>
  *   </section>
  *   <aside class="ui-component-notice"></aside> // optional notice (e.g. for error messages)
  * </div>
  *
  * OR:
  *
  * <div class="ui-component-status-bar">
  *   <button class="ui-component-button"></button> // [0]
  * </div>
  *
  * Note[0]: A single `button` can be used without a wrapping `section` e.g. for
  * navigation. If more than one button, use a `div` container and they will all
  * be pushed to the right.
  */
.ui-component-status-bar {
  position: sticky;
  will-change: transform;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding-top: $space-sm;
  padding-bottom: $space-sm;
  margin-top: auto; // push to bottom of any flex container parent
  background-color: #fff;
  transition: transform .4s; // for .is-hidden
  @include wrapper-with-padding;

  // Extend background beyond wrapper
  &::before {
    display: block;
    content: "";
    width: 100vw;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;

    :not(.ui-component-section) + & { // avoid double-border when adjacent sibling of section component
      border-top: $ui-border;
    }
  }

  &.is-sticky {
    z-index: $z-index-sticky;

    &::before {
      box-shadow: 0 -4px 6px rgba($black, .1);
    }
  }

  &.is-hidden {
    overflow: hidden;
    transform: translate3d(0, 100%, 0);
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: $space-xs;

    @include mobile-up {
      flex-direction: row;
      row-gap: 0;
      column-gap: $space-xs;
    }

    header {
      flex-basis: 100%;
      display: flex;
      column-gap: $space-3xs;
      align-items: baseline;
      margin-bottom: 0;
      text-align: center;

      @include mobile-up {
        flex-direction: column;
        text-align: unset;
      }

      > :first-child {
        font-size: $base-font * $scale;
        font-weight: 700;
        line-height: 1;
      }

      + .ui-component-button {
        flex-basis: 100%;
        flex-shrink: 1;

        @include mobile-up {
          flex-basis: auto;
        }
      }
    }

    & + aside {
      margin-bottom: $space-sm;
    }
  }

  > .ui-component-button {
    width: 100%;
    margin-left: auto;

    @include phablet-up { // phablet and not mobile because .ui-component-button is full-width on phablet
      width: auto !important;
    }
  }

  > div { // group of buttons in a `div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-2xs);
    margin-left: auto;

    > .ui-component-button {
      width: auto !important;
    }
  }

  &.variant\:calendar {
    .ui-component-button.subtext\:left > span:first-of-type {
      opacity: 1;
    }
  }

  &.variant\:venue {
    z-index: $z-index-sticky; // because sticky subnav is in same stacking context

    &:not(.is-sticky)::before {
      border-top: none; // avoid double-border with preceding `section`
    }
  }
}
