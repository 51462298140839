@import "../config/config";
@import "../../settings/colors";

.fa\:after {
  margin-left: .75ch;
}

.fa\:before {
  margin-right: .75ch;
}

.fa\:green {
  color: $sb-green;
}

.fa\:red {
  color: $sb-red;
}

.fa\:yellow {
  color: $yellow;
}

.fa\:theme {
  &, .theme\:primary & {
    color: $sb-green;
  }

  .theme\:premium & {
    color: $sb-gold;
  }
}
