@import "config/config";
@import "helpers/background";
@import "helpers/fontawesome";
@import "helpers/wrapper";

/**
 * Markup:
 * <{section,article,aside} class="ui-component-voucher-promo
 *                                 variant:{image-right}">
 *   <div></div>
 *   <div>
 *     <form></form>
 *   </div>
 *   OR:
 *   <div>
 *     <div data-img></div>
 *   </div>
 *   <div></div>
 * </{section,article,aside}>
 */
.ui-component-voucher-promo {
  display: flex;
  flex-direction: column;
  gap: $space;
  width: 100%;
  text-align: center;
  background-color: $sb-green-100;

  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; // for pseudo-element icons

    > * {
      z-index: 1; // keep children above any pseudo-element icon on parent

      &:last-child {
        margin-bottom: 0;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: $space-sm;
    }
  }

  &.variant\:image-right {
    overflow: hidden; // for border-radius clipping
    border-radius: $ui-border-radius-lg;
    box-shadow: $ui-box-shadow-heavy;

    @include phablet-up {
      flex-direction: row;
    }

    > div {
      flex-basis: 50%;
      padding: $space;

      &:last-child {
        align-items: center;
      }

      @include tablet-landscape-up {
        padding: $space-xxl;

        [data-img] {
          margin: -$space-xxl;
        }
      }
    }

    [data-img] {
      height: 100%;
      min-height: 200px;
      width: auto;
      margin: -$space;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    @include phablet-up {
      flex-flow: row-reverse;

      [data-img] {
        position: absolute;
        width: 100%;
      }
    }
  }
}
