@use "sass:math";

$perfect-fourth: 1.333; // https://www.modularscale.com/?1&rem&1.333
$scale: $perfect-fourth;

$min-screen: 22.5rem; // 360px
$max-screen: 90rem; // 1440px

$base-font: 1rem;
$baseline: round(1.5 * $base-font * 1000) * 0.001;

// Deprecated
$space: var(--space);
$space-sm: var(--space-sm);
$space-xs: var(--space-xs);
$space-xxs: var(--space-2xs);
$space-3xs: var(--space-3xs);
$space-lg: var(--space-lg);
$space-xl: var(--space-xl);
$space-xxl: var(--space-2xl);
$space-3xl: var(--space-3xl);

$grey-100: #f6f7f9;
$grey-200: #d0d1d3;
$grey-300: #6e7785;
$grey-400: #4A515B;
$black: #4f4f4f;
$sb-black-new: #333333;
$sb-blue-100: #ebf1ff;
$sb-blue-200: #bacaef;
$sb-blue: #0d48a5;
$sb-gold-100: #f5efe8;
$sb-gold: #a99255;
$sb-gold-500: #7a6b3e;
$sb-green-100: #e1f3f2;
$sb-green-200: #6dc5bf;
$sb-green-300: #41a49d;
$sb-green: #2b8281;
$sb-green-500: #1f4a4b;
$sb-orange-100: #ffefd9;
$sb-orange: #ea580c;
$sb-orange-500: #b84c18;
$sb-purple-100: #eeedff;
$sb-purple: #7d7dad;
$sb-purple-500: #5e3580;
$sb-red-100: #ffeae6;
$sb-red-200: #ffa29b;
$sb-red: #cc3027;
$sb-yellow-100: #fef9ec;
$sb-yellow-200: #f7e9c1;
$sb-yellow: #e5b93a;

$rewards-blue: #003366;

$ui-border: 1px solid $grey-200;
$ui-border-light: 1px solid $grey-400;
$ui-border-radius: 3px;
$ui-border-radius-lg: 5px;
$ui-box-shadow: 0 1px 3px rgba($black, .1);
$ui-box-shadow-heavy: 0 2px 10px 1px rgba($black, .2);
$ui-box-shadow-heavy-darker: 0 2px 10px 1px rgba(#000, .2);

$ui-opacity-disabled: .5;
$ui-opacity-anc: .8; // for ancillary text

$z-index-sticky: 1000;
$z-index-dropdown: 1010;
$z-index-snackbar: 1040;
$z-index-modal: 1050;
$z-index-spinner: 1060;
