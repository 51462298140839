.unavailable-package--action {
  @extend .offer;
  padding: sb-rem($spacing-unit);

  p {
    min-height: em(60px);
  }
}

.unavailable-package {
  margin-top: sb-rem($spacing-unit);
  text-align: center;
}
