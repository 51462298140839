@use "sass:math";

@import "../shared/helpers/links";

// Set values used by Stimulus target
[data-search-map-target="map"] {
  --float-marker-content: false;

  @include container-style("--float-marker-content", true) {
    --float-marker-content: true;
  }
}

.search__filters,
.search__results,
.search__map {
  --column-box-shadow: 8px 0 6px -3px #{$black}33; // 33 hex ~ 20% transparency
}

.search__results,
.search__map {
  [data-img] {
    position: relative;

    // Elysium logo
    img[alt="Elysium Collection"] {
      position: absolute;
      top: var(--space-xs);
      left: var(--space-xs);
      height: auto !important;
      width: auto !important;
      max-width: 125px;
    }
  }
}

.search__map {
  .ui-component-card {
    // "x" close icon on image
    [data-img] > button {
      z-index: 1;
      padding: var(--space-3xs);
      color: #fff;
      opacity: $ui-opacity-anc;
    }
  }
}

body.--map-open {
  overflow: hidden;

  .sb-navbar {
    display: none;
  }

  #gtm-whatsapp {
    z-index: 0 !important;
  }

  .controller\:searches {
    .page--search {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      width: 100%;
      height: 100dvh;
      z-index: 1;
      box-shadow: inset 0 4px 6px -3px rgba($black, .2),
                  inset 0 -4px 6px -3px rgba($black, .2);
      background: $grey-100;
      container-type: size; // For #js-google-map container query units

      @include container-style("--screen-desktop-search", true) {
        top: var(--search-bar-height, 0px);
        height: calc(100dvh - var(--search-bar-height, 0px));
      }

      > .wrapper,
      .ui-component-layout.variant\:search {
        height: 100%;
      }

      > .wrapper {
        max-width: 120rem;
        padding: 0;
      }
    }
  }

  // Google maps container
  #js-google-map {
    height: 100%;
  }

  .search__results {
    // Hide visually instead of `display: none`, because with the latter if map open on first
    // load then search card images won't get loaded, which means they're missing when close map
    z-index: -1;
    overflow: hidden;
    height: 0;
  }

  .search__map {
    position: relative; // For absolute floating marker content
    height: calc(100dvh - var(--search-bar-height, 0px));
    align-self: flex-end;
  }

  @include container-style("--screen-desktop-search", true) {
    .search__filters,
    .search__results {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 100cqh;
      padding-top: var(--space);
      padding-bottom: var(--space);
      box-shadow: var(--column-box-shadow);
    }

    .search__filters {
      z-index: 2; // Keep box-shadow above results toggle button
    }

    .search__results {
      z-index: 1; // Keep box-shadow above map
      height: 100%;
      overflow: auto;

      // Hide promo/loyalty notices, breadcrumbs, header, intro, pagination, etc
      > :not(article),
      li:has(.ui-component-notice) {
        display: none;
      }

      &:not(:has(.search__spas-found)) .search__more-locations {
        display: block !important;
      }
    }

    &.--results-collapsed .search__results {
      overflow: hidden;
      min-width: 0 !important;
      flex-basis: 0 !important;
      padding-right: 0 !important;
      opacity: 0;
    }
  }
}

.search__map__show-map {
  width: auto !important;
  padding: var(--space-2xs) var(--space) !important;
}

.search__map__show-map--mobile {
  float: right;
  margin-left: var(--space);

  @include container-style("--screen-desktop-search", true) {
    display: none !important;
  }
}

.search__map__show-map-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-xl);
  margin-bottom: var(--space);
  background: url("../images/bg-map.svg");
  background-size: cover;

  body.--map-open & {
    display: none;
  }

  @include container-style("--screen-desktop-search", false) {
    display: none !important;
  }
}

.search__map__hide-map {
  width: auto !important;
  padding: var(--space-2xs) var(--space) !important;
  margin: 10px; // For some reason Google custom controls don't apply this automatically
}

.search__map__toggle-results {
  display: none;

  @include container-style("--screen-desktop-search", true) {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    padding: var(--space-xl) var(--space-xs) var(--space-xl) 0;
    appearance: none;
    cursor: pointer;
    border: none;
    background: $grey-100;
    box-shadow: var(--column-box-shadow);
    border-top-right-radius: $ui-border-radius-lg;
    border-bottom-right-radius: $ui-border-radius-lg;

    .fa-chevron-left {
      transition: transform 0.2s;
    }
  }

  body.--results-collapsed & {
    box-shadow: none;

    .fa-chevron-left {
      transform: rotate(180deg);
    }
  }
}

.search__map__marker,
.search__map__floating-marker-content {
  .marker-card .search-result__venue-info {
    flex-wrap: nowrap;
    justify-content: space-between;
    line-height: 1;

    > footer {
      flex-basis: auto !important;

      > .ui-component-from-price {
        align-items: flex-end;
      }
    }
  }
}

.search__map__marker {
  font-size: $base-font;
  font-family: "Lato", sans-serif;

  // Marker wedge
  > ::after,
  .marker-pin > div::after {
    display: block;
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -1px);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom: none;
    border-top-color: $sb-green;
  }

  .marker-pin {
    padding: 1px;
    background-color: $sb-green;
    border-radius: $ui-border-radius-lg;

    > div {
      position: relative;
      padding: var(--space-3xs) var(--space-2xs);
      background-color: #fff;
      border-radius: $ui-border-radius-lg - 1px;
      color: $sb-green;

      &::after {
        z-index: 1;
        border-top-color: #fff;
      }
    }
  }

  .marker-card {
    display: none;
    cursor: auto;

    &::after {
      border-width: 12px;
      border-top-color: #fff;
    }
  }

  &.--highlighted,
  &.--active {
    .marker-pin > div {
      background-color: $sb-green;
      color: #fff;

      &::after {
        border-top-color: $sb-green;
      }
    }
  }

  &.--highlighted {
    .marker-pin {
      animation: bounce .7s infinite ease-in-out;
      transform-origin: bottom;

      @keyframes bounce {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-10px); }
        100% { transform: translateY(0); }
      }
    }
  }

  &.--active {
    .marker-card {
      display: flex;
      width: 360px;
    }

    @include container-style("--float-marker-content", false) {
      .marker-pin {
        display: none;
      }
    }
  }
}

.search__map__floating-marker-content {
  position: absolute;
  z-index: $z-index-sticky - 1;
  bottom: var(--space-lg);
  left: var(--space-xs);
  right: var(--space-xs);
  display: flex;
  justify-content: center;

  .marker-card {
    flex-basis: max-content !important;
    min-width: 100%;
    max-width: none !important;

    [data-img] {
      aspect-ratio: 2 !important;
    }
  }

  &:empty {
    display: none;
  }
}

.search__map__floating-bumper {
  position: absolute;
  z-index: $z-index-sticky - 1;
  bottom: var(--space-lg);
  left: var(--space-xs);
  right: var(--space-xs);
  display: flex;
  justify-content: center;

  &:empty {
    display: none;
  }

  img {
    display: none; // Hide icon
  }

  @include container-style("--screen-desktop-search", true) {
    display: none;
  }
}

.search__spas-found {
  display: flex;
  gap: var(--space);
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space);

  > span {
    font-size: $base-font * $scale;
    font-weight: 700;
  }
}

.search-result__mobile-link {
  display: block;
  @include unstyled-link();

  &:hover,
  &:focus {
    background-color: $green--lightest;
  }
}

.search-label {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 1rem;
}

a.breadcrumbs {
  &::after {
    content: "\00a0\003e";
  }
  &:last-of-type::after {
    content: "";
  }
}

.search-result.ui-component-card {
  min-height: 260px;

  &.theme\:premium a {
    color: $sb-gold;
  }

  :has(> &) {
    flex-basis: 100%;
  }
}

section.search-result__carousel {
  flex-basis: 240px !important;
  aspect-ratio: 2 !important;

  + div {
    margin-top: 0 !important;
  }

  .ui-component-carousel {
    &, & > div {
      height: 100%;
    }
  }
}

.search-result__venue-info {
  flex: 1;
  margin-bottom: calc(-1 * var(--space)) !important; // Undo double gap from card component

  > section {
    > header { // Venue name, rating, location
      > p { // Undo card styles
        font-weight: 400;
        font-size: $base-font;
      }
    }

    > section { // Facilities
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;

      span:first-child:last-child { // Bullets
        margin: 0 3px;
      }
    }
  }

  > footer .search-label {  // Available as a gift
    justify-content: flex-end;
    margin-bottom: var(--space-xs);
  }
}

.search__more-locations {
  margin-top: var(--space-xl);

  body.--map-open & ol {
    columns: 2;
  }
}

.search__load-more,
.search__load-previous {
  margin-top: 16px;
  margin-bottom: 16px;
}

.search__load-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.search__progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  width: 100%;

  p {
    margin: 0;
  }

  progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;
  
    width: 100%;
    max-width: 320px;
    height: 8px;

    &::-webkit-progress-bar {
      background-color: $grey-200;
    }

    &::-webkit-progress-value {
      background-color: $sb-black-new;
    }
  }
}
