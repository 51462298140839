.offer--spa-choice {
  @include media(tab-and-up) {
    height: calc(100% - 1.5rem);

    .offer__container {
      height: calc(100% - 14.0625rem); // 225px == height of __img-container
    }
  }

  &:hover .offer__img {
    transform: none;
  }

  .offer__footer {
    align-items: flex-start;
    margin-top: auto;
  }
}
