.site-logo {
  width: $logo-width;
  max-width: 140px;
  position: relative;
  margin-right: auto;

  @include tablet-portrait-up {
    max-width: 50%;
  }

  > a {
    display: block;
    width: 100%;
    height: $logo-height;
    background-image: url("../images/v3-logo-sb-navbar.svg");
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}
