.error-explanation {
  background: $red;
  color: #fff;
  padding: $spacing-unit--small;
  border: 1px solid $red--dark;
  margin-bottom: $spacing-unit--small;
  margin-top: $spacing-unit--small;

  h2 {
    margin-top: 0;
    margin-bottom: $spacing-unit--small;
    @extend h3;
  }

  ul {
    margin-bottom: $spacing-unit--small;
  }
}

.field_with_errors {
  input, textarea {
    border: 1px solid $red;
  }

  label {
    color: $red;
  }
}
