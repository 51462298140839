@import "../config/config";
@import "./media-queries";
@import "./units";

$forms--min-input-width: sb-rem(300px);

@mixin forms--input-appearance {
  display: block;
  width: 100%;
  padding: $space-xxs;
  font-size: 16px; // can't use 1rem because (sadly) setting font-size: 14px on body on small screens, which causes input focus zoom on iOS
  font-weight: 400;
  line-height: $baseline;
  color: $black;
  background-color: #fff;;
  border: $ui-border;
  border-radius: $ui-border-radius;
  box-shadow: $ui-box-shadow;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    font-size: .9rem;
    opacity: $ui-opacity-anc;
  }

  &:focus {
    outline-color: $sb-green;
    outline-width: thick;

    .theme\:premium & {
      outline-color: $sb-gold;
    }

    &::placeholder {
      opacity: 0 !important;
    }
  }

  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.invalid {
    border-color: $sb-red;

    &::placeholder {
      opacity: 0 !important;
    }
  }

  &[disabled] {
    @include forms--input-disabled;
  }

  // Used in My Account
  &[readonly] {
    cursor: not-allowed;
    background-color: $grey-100;
  }

  &[data-type="datepicker"][readonly] {
    cursor: pointer;
    background-color: #fff;
    caret-color: transparent;
  }
}

@mixin forms--input-disabled {
  cursor: not-allowed;
  opacity: $ui-opacity-disabled;
}

@mixin forms--input-width($size) {
  width: calc(#{$size}ch + var(--space) * 2 + 2px) !important;
}

@mixin forms--fieldset-appearance {
  margin: 0;
  padding: 0;
  border: 0;
}

// @TODO: remove when global styles sorted out
@mixin forms--legend-appearance {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  border: none;
  font-size: 16px; // can't use 1rem because body font-size: 14px on small screens
  font-weight: 700;
  line-height: $baseline;
  color: $black;
}

@mixin forms--wrapped-widths {
  @include phablet-up {
    // Form children are full-width by default...
    &.width\:grow { // ...or grow when wrapped
      flex-grow: 1;
      flex-basis: $forms--min-input-width;
    }

    &.width\:auto { // ...or maintain 'natural' width
      flex-grow: initial;
      flex-basis: $forms--min-input-width;

      &.ui-component-input-group {
        flex-basis: auto;
      }
    }

    &.width\:sized { // ...or have specific width
      flex-grow: initial;
      flex-basis: auto;

      > [size="8"], > [data-size="8"] {
        @include forms--input-width(8);
      }

      > [size="16"], > [data-size="16"] {
        @include forms--input-width(16);
      }
    }
  }
}

@mixin forms--stacked-widths {
  flex-basis: auto;
  width: 100%;

  @include phablet-up {
    &.width\:grow {
      min-width: $forms--min-input-width;
      width: auto;
    }

    &.width\:auto {
      min-width: $forms--min-input-width;
      width: min-content;
    }

    &.width\:sized {
      width: auto;
    }
  }
}

@mixin forms--required-suffix($unicode: "\2a") {
  @include fa-pseudo($unicode);
  color: $sb-red;
  margin-left: .5ch;
  font-size: .5rem;
  font-weight: 400;
}
