@import "../helpers/forms";

$range-slider-thumb-size: sb-rem(25px);

@mixin range-slider-thumb {
  position: relative;
  pointer-events: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: $range-slider-thumb-size;
  height: $range-slider-thumb-size;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid $green;
  cursor: pointer;
  transition: all $transition-duration--short;
  z-index: 2;

  &:hover {
    border-color: $green--dark;
    transform: scale(1.1);
  }
}

.range-slider {
  position: relative;
  z-index: 0;
  width: 100%;
  height: $range-slider-thumb-size;
  margin-bottom: calc(2 * var(--space));

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    z-index: -1;
    width: 100%;
    height: 3px;
    @include input-appearance;
  }

  input[type="range"],
  .range-slider__active-range {
    position: absolute;
    top: 0;
    left: 0;
  }

  .range-slider__active-range {
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    background: $green;
  }

  input[type="range"] {
    @include input-appearance;
    width: 100%;
    z-index: 1;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    pointer-events: none;
    outline: none;
    touch-action: none; // disable browser handling of gestures

    &::-webkit-slider-thumb {
      @include range-slider-thumb;
    }

    &::-moz-range-thumb {
      @include range-slider-thumb;

      & {
        background-color: #fff;
        border-color: $green--dark;
      }

      &:hover {
        background-color: $green--dark;
      }
    }

    &:focus, &:active {
      &::-webkit-slider-thumb {
        border-color: $green--dark;
      }

      &::-moz-range-thumb {
        background-color: $green--dark;
      }
    }

    + span {
      position: absolute;
      top: 100%;

      &:first-of-type {
        transform: translateX(-125%);
      }

      &:last-of-type {
        transform: translateX(-50%);
      }

      &[contenteditable] {
        margin-top: var(--space-2xs);
        padding: 0 var(--space-2xs);
        @include input-appearance;

        &::before {
          display: inline;
          content: "£";
        }

        &:focus {
          outline: none;
          border-color: $sb-green;
        }

        &:first-of-type {
          transform: translateX(-40%);
        }

        &:last-of-type {
          transform: translateX(-80%);
        }
      }
    }
  }
}

@mixin range-slider-ie {
  .range-slider {
    margin-bottom: sb-rem($spacing-unit);

    input[type="range"] {
      position: relative;
      height: 12px;
      pointer-events: auto;
      cursor: pointer;
      padding: 0;

      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: transparent;
      }

      &::-ms-track {
        background: $grey--lighter;
        border: 1px solid $grey;
        border-radius: 3px;
        color: transparent;
      }

      &::-ms-tooltip {
        display: none;
      }

      &::-ms-thumb {
        @include range-slider-thumb;

        & {
          height: 10px;
          background-color: $green;
          border-color: $green;
          border-radius: 0;
        }

        &:hover {
          background-color: $green--dark;
        }
      }

      &:focus, &:active {
        &::-ms-thumb {
          border-color: $green--dark;
        }
      }

      + span {
        position: static;
        margin-top: 0;
        margin-bottom: $range-slider-thumb-size;

        &:first-of-type {
          transform: none;

          &::before {
            display: inline-block;
            content: "Minimum:\00a0";
          }
        }

        &:last-of-type {
          float: left;
          transform: none;

          &::before {
            display: inline-block;
            content: "Maximum:\00a0";
          }
        }
      }
    }

    .range-slider__active-range {
      display: none;
    }
  }
}

@media screen and (-ms-high-contrast: none) { // IE11
  @include range-slider-ie;
}

@supports (-ms-ime-align: auto) { // Edge
  @include range-slider-ie;
}
