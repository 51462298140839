@import "../ui_components/helpers/fontawesome";

.progress-tracker {
  display: flex;
  list-style: none;
  padding: sb-rem($spacing-unit) sb-rem($spacing-unit--large);
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1rem;

  li {
    counter-increment: steps;
    position: relative;
    flex: 1;
    white-space: nowrap;
    opacity: .5;
    z-index: 0;

    &:last-child {
      flex: 0;
    }

    &::before {
      display: block;
      content: counter(steps);
      font-weight: 700;
      width: 2rem;
      height: 2rem;
      line-height: 1.5;
      text-align: center;
      border: 4px solid;
      border-radius: 50%;
      background-color: #fff;
      color: $grey;
    }

    &::after {
      display: block;
      content: "";
      z-index: -1;
      position: absolute;
      top: calc(50% - 2px);
      width: 100%;
      height: 4px;
      background-color: $grey;
    }

    span {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      transform: translateX(calc(-50% + 1rem));
    }

    &.completed, &.active {
      opacity: 1;
    }

    &.completed {
      &::before {
        @include fa-pseudo("\f00c"); // fa-check
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $green;
        border-color: $green;
      }

      &::after {
        background-color: $green;
      }
    }

    &.active {
      &::before {
        color: $green;
        border-color: $green;
      }

      &::after {
        opacity: .5;
      }

      span {
        font-weight: 700;
      }
    }
  }
}
