// @TODO: Create entry point for showcase pages and move these imports there
@use "sass:math";

@import "../ui_components/card";
@import "../ui_components/layout";

.hero-banner {
  h1 {
    font-weight: bold;
  }

  h2 {
    margin-bottom: 0;

    + p {
      margin-top: sb-rem($spacing-unit);
    }
  }
}

.hero-banner--divider {
  display: flex;
  width: 100%;
  background-color: $green--lightest;
  margin-bottom: 0;
  box-shadow: none;
  text-shadow: none;

  @include media-query("palm-and-up") {
    min-height: sb-rem(200px);
  }

  .hero-banner__content {
    background: none;
    color: inherit;
    align-items: center;
    justify-content: space-between;

    @include media-query("handheld") {
      flex-wrap: wrap;
    }

    a + a {
      @include media-query("tab-and-up") {
        margin-left: sb-rem($spacing-unit);
      }

      @include media-query("mobile") {
        display: block;
        margin-top: sb-rem($spacing-unit);
      }
    }

    > div {
      padding: em($spacing-unit--small);

      @include media-query("lap-and-up") {
        p:last-child {
          margin: 0;
        }
      }
    }
  }
}

.cta-banner__link {
  @extend .h2;
  margin: 0;
}

.usp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.usp__icon {
  $columns: 3;
  padding: $spacing-unit;
  flex: 1 0 100%;

  @include media-query("tab-and-up") {
    flex: 0 0 math.div(100%, $columns);
  }

  img {
    height: em(150px);
  }
}

.display-card {
  margin-bottom: $spacing-unit;
}

.hero-banner--region,
.hero-banner--category {
  font-weight: 700;
  text-shadow: 1px 1px 5px #000;
}
