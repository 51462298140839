@import "config/config";
@import "helpers/fontawesome";
@import "helpers/units";
@import "helpers/utilities";

/**
 * Markup:
 * <label class="ui-component-{checkbox, radio-button}
 *               align-control:{baseline*, center}
 *               variant:{link}">
 *   <input type="{checkbox, radio}">
 *   <span>Label text</span>
 *   <span class="utils:error"></span> // optional error message
 * </label>
 *
 * Note: The inner `span` can contain at most one child. So if its text content
 * contains other tags (`a`, etc.) then the entirety needs to be wrapped in
 * another `span` to ensure max one child, like:
 * `<span><span>I am a <a>checkbox label</a></span></span>`
 */
.ui-component-checkbox,
.ui-component-radio-button {
  position: relative; // for absolute input
  display: flex;
  flex-direction: column;
  align-items: baseline;
  cursor: pointer;
  margin-bottom: 0;
  @include user-select-none;

  input:not([type=date]) {
    position: absolute;
    margin: 0;
    padding: 0;
    opacity: 0;

    + span {
      display: inline-flex;
      align-items: baseline;
      width: 100%;
      line-height: $baseline;
      font-weight: 400;

      &::before {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-right: $space-xxs;
        text-align: center;
        transition: .1s;
      }
    }

    &[type="checkbox"] {
      + span::before {
        @include fa-pseudo("\f00c"); // fa-check
        display: flex;
        align-items: center;
        width: sb-rem(20px);
        height: sb-rem(20px);
        color: #fff;
        background-color: #fff;
        border: $ui-border;
        border-width: 2px; // can't use rem because body font-size: 14px on small screens
        border-radius: $ui-border-radius;
      }

      &:focus + span::before,
      &:checked + span::before {
        border-color: $sb-green;

        .theme\:premium & {
          border-color: $sb-gold;
        }
      }

      &:checked + span::before {
        background-color: $sb-green;

        .theme\:premium & {
          background-color: $sb-gold;
        }
      }
    }

    &[type="radio"] {
      + span::before {
        content: " ";
        align-items: normal;
        width: sb-rem(18px);
        height: sb-rem(18px);
        border-radius: 100%;
        background-color: #fff;
        border: 3px solid #fff;
        box-shadow: 0 0 0 2px $grey-200;
      }

      &:focus + span::before,
      &:checked + span::before {
        background-color: $sb-green;
        box-shadow: 0 0 0 2px $sb-green;

        .theme\:premium & {
          background-color: $sb-gold;
          box-shadow: 0 0 0 2px $sb-gold;
        }
      }
    }

    &.invalid + span::before {
      &, .theme\:premium & {
        border-color: $sb-red;
      }
    }

    &[disabled] {
      &, + span {
        @include forms--input-disabled;
      }
    }
  }

  &.align-control\:center {
    input + span {
      align-items: center;
    }
  }

  &.variant\:link {
    input + span {
      @include text-link;

      &::before {
        display: none;
      }
    }
  }
}
