@use "sass:math";

@import "config/config";

/**
 * Markup:
 * <div class="ui-component-feature
 *             variant:{call-us, competition, generic, groups, elysium}">
 *   <{i, img}>
 *   <article class="ui-component-article"></article>
 * </div>
 */
.ui-component-feature {
  text-align: center;

  ol, ul {
    text-align: left;
  }

  article header {
    h2, h3, h4 {
      margin: 0;
    }
  }

  article header, i, img {
    margin-bottom: $space-xs;
  }

  .ui-component-layout & {
    flex-basis: sb-rem(320px);
  }

  &.variant\:generic,
  &.variant\:groups {
    i {
      color: $sb-green;
    }
  }

  &.variant\:elysium {
    i {
      color: $sb-gold;
    }
  }

  &.variant\:call-us,
  &.variant\:competition {
    header {
      padding-bottom: $space-sm;
      border-bottom: 1px solid;
    }
  }

  &.variant\:call-us {
    section {
      font-size: 1rem * $scale;
    }

    a[href^="tel"] {
      font-size: 1rem * math.pow($scale, 2);
      font-weight: 700;
      border-bottom-width: 2px !important;
    }

    ol {
      width: 75%;
      margin: 0 auto;
      padding: 0;
      list-style-type: none;

      li {
        display: flex;
        justify-content: space-between;
      }

      + ol {
        margin-top: $space-sm;
        padding-top: $space-sm;
        border-top: 1px solid;
      }
    }
  }
}
