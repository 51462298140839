@import "config/config";
@import "helpers/forms";
@import "helpers/media-queries";

@mixin input-group--inline {
  flex-direction: row;
  gap: $space-xs;

  button {
    width: auto !important;
  }
}

/**
 * Markup:
 * <fieldset class="ui-component-input-group
 *                  required<false*, true> // only if the entire group is required
 *                  variant:{stacked*, inline, inline-always}">
 *   <legend>Fieldset caption</legend> // optional, or use .sr-only
 *   <label class="ui-component-input"></label>
 *   <label class="ui-component-input"></label>
 *   ...
 *   <button class="ui-component-button">Button text</button> // optional button
 * </fieldset>
 * <span class="input-group-hint"></span> // optional hint text
 * <span class="utils:error"></span> // optional error message
 */
.ui-component-input-group {
  @include forms--fieldset-appearance;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  text-align: left;
  gap: $space;

  legend {
    @include forms--legend-appearance;
  }

  &.required legend::after {
    @include forms--required-suffix;
  }

  .ui-component-form & {
    ~ span {
      margin-top: -$space-sm + sb-rem(2px);
    }

    ~ .input-group-hint {
      @include text-small;
      @include text-muted;
    }
  }

  &.variant\:inline {
    :has(> &) {
      container-type: inline-size;
    }

    @container (width >= #{map-get($_mq-breakpoints, phablet-up)}) {
      @include input-group--inline;
    }
  }

  &.variant\:inline-always {
    @include input-group--inline;
  }

  &:not(.variant\:inline-always) {
    > .ui-component-input,
    > .ui-component-input-group {
      @include forms--stacked-widths;
    }
  }
}
