@use "sass:color";

@import "config/config";
@import "helpers/background";
@import "helpers/media-queries";
@import "helpers/wrapper";

$section--accent-bg-color: $grey-100;
$section--accent-border-color: color.adjust($section--accent-bg-color, $lightness: -5%);

/**
  * Markup:
  * <section class="ui-component-section
  *                 variant:{default*, image, banner-image, trustpilot}
  *                 width:{sm, md*, lg}
  *                 bg:{white, accent, inverse, wave, big-wave, multiwave, blockwave}">
  *
  *   <div data-img=""></div> // for `variant:image`
  *   <article></article>
  *
  *   // for `variant:banner-image`
  *   <div class="ui-component-banner"></div>
  *   <div class="ui-component-section width:lg">
  *     <article></article>
  *   </div>
  * </section>
  *
  * By default, backgrounds of even sections are white and odd sections are
  * accent-coloured. To invert this, use `bg:inverse` on adjacent sections.
  */
.ui-component-section {
  --mixin-clamped-min: var(--space-scale) * var(--space-scale);
  --mixin-clamped-max: var(--mixin-clamped-min) * var(--mixin-clamped-min);

  position: relative; // for absolute ::before
  z-index: 0; // for stacking context of ::before
  width: 100%;
  padding-top: var(--mixin-clamped);
  padding-bottom: var(--mixin-clamped);
  @include wrapper-with-padding;

  .ui-component-modal & {
    padding-top: $space;
    padding-bottom: $space;
  }

  &.width\:sm {
    @include wrapper-with-padding(sm);
  }

  &.width\:lg {
    @include wrapper-with-padding(lg);
  }

  &:not(.variant\:banner-image) & { // support for nested `section.ui-component-section`s
    --mixin-clamped-min: var(--space-scale) / var(--space-scale);
    --mixin-clamped-max: var(--space-scale) * var(--space-scale) * var(--space-scale);

    z-index: initial;
    padding: var(--mixin-clamped) 0;

    &::before {
      display: none;
    }

    &:not(:last-of-type) {
      border-bottom: $ui-border;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &::before {
    background-color: #fff;
    @include background-extend;
  }

  &:nth-child(even).bg\:inverse::before,
  &:nth-child(odd):not(.bg\:inverse)::before {
    background-color: $section--accent-bg-color;
    border-top: 1px solid $section--accent-border-color;
    border-bottom: 1px solid $section--accent-border-color;
  }

  &.bg\:white::before {
    background-color: #fff !important;
    border: none !important;
  }

  &.bg\:accent::before {
    background-color: $section--accent-bg-color !important;
    border: none !important;
  }

  &.bg\:wave::before,
  &.bg\:big-wave::before {
    background-image: url("../images/wave-full.svg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 100% 30%;
    background-color: #fff !important;
    border: none !important;
  }

  &.bg\:big-wave::before {
    background-size: 200vw 100%;

    @include desktop {
      background-size: 100% 100%;
    }
  }

  &.bg\:blockwave,
  &.bg\:multiwave {
    &::before {
      background-image: url("../images/block-wave.svg");
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: contain;
      background-color: #fff !important;
      border: none !important;
    }
  }

  &.bg\:multiwave::after {
    background-image: url("../images/wave-full-width.svg");
    background-repeat: no-repeat;
    background-position: bottom 22.5vw left;
    background-size: contain;
    @include background-extend;
  }

  &.bg\:blockwave::before {
    background-position-y: 0%;
    background-size: cover;
  }

  &.variant\:image {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: var(--space-xl);
    row-gap: var(--space-lg);

    > [data-img] {
      width: 100%;
      max-width: sb-rem(480px);
    }

    @include tablet-landscape-up {
      > [data-img] {
        flex-basis: 50%;
        flex-shrink: 0;
        max-width: none;
      }

      > article {
        flex-basis: 50%;
        text-align: left;
      }
    }

    &,
    & ~ & ~ &,
    & ~ & ~ & ~ & ~ & {
      @include tablet-landscape-up {
        flex-direction: row;
      }
    }

    & ~ &,
    & ~ & ~ & ~ &,
    & ~ & ~ & ~ & ~ & ~ & {
      @include tablet-landscape-up {
        flex-direction: row-reverse;
      }
    }
  }

  &.variant\:banner-image {
    padding: 0;
    max-width: none;

    .ui-component-section::before {
      display: none; // remove background on nested section
    }

    .ui-component-banner > article {
      width: 100%; // full-width for any content inside banner
    }

    @include tablet-landscape-up {
      .ui-component-banner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 50vw;
      }

      .ui-component-section > article {
        width: 50%;
        padding-right: $space;
        text-align: left;
      }
    }

    @include widescreen {
      @include wrapper(lg);

      .ui-component-banner {
        width: 50%;
      }
    }
  }

  &.variant\:trustpilot {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }
}
