@use "sass:math";

@import "../../ui_components/config/config"; // $z-index-*

html,
body {
  overflow-x: clip; // Prevent horizontal overflow on iOS >= 16 without breaking `sticky` elements
}

html {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-size: math.div($base-font-size, 16px) * 1rem;
  line-height: $base-line-height-factor;
  background-color: #fff;
  color: $color-text;
  min-height: 100%;
  touch-action: manipulation; // disable double-tap to zoom, in order to avoid tap delay on mobile

  @include media-query("handheld") {
    font-size: math.div($base-font-size--mobile, 16px) * 1rem;
  }
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.page {
  background-color: $color-bg;
  color: $black;
  padding-top: sb-rem($spacing-unit);

  .form-group.required label:after {
    content: "*";
  }

  > section,
  > .section {
    padding-top: em($spacing-unit--large);
    padding-bottom: em($spacing-unit--large);

    &:nth-of-type(odd) {
      background-color: $color-bg;
    }

    &:nth-of-type(even) {
      background-color: #fff;
    }
  }

  &.page--packages,
  &.page--search {
    padding-bottom: sb-rem($spacing-unit);
  }

  &.page--venue,
  &.page--premium {
    padding-top: 0;

    > section {
      background-color: $color-bg;
    }
  }

  &.page--premium > section#about {
    background-color: $color-premium;
    color: #fff;
  }

  &.page--elysium-collection > section:nth-of-type(odd) {
    background: $color-premium;
    color: #fff;
  }

  &.page--error {
    padding: 0;
  }
}

// see webchat tag in GTM: https://tagmanager.google.com/#/container/accounts/109977544/containers/1411128/workspaces/1000184/tags/234
.ie-div-position-customer-chat {
  > .button-iframe-wrap {
    z-index: $z-index-sticky - 1;

    body.--map-open & {
      z-index: -1;
    }
  }

  > .chat-iframe-wrap {
    z-index: $z-index-modal - 1;
  }
}
