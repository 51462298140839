@import "config/config";

/**
 * Markup:
 * <{span,div} class="ui-component-text-group
 *                    items:{center*, top}
 *                    overflow:{default*, ellipsis}
 *                    direction:{normal*, reverse}
 *                    variant:{default*, pull-right, pull-left, bookends}">
 *   <{span,div}>Pulled-left text</{span,div}> // optional
 *   <{span,div}>
 *     Primary text
 *   </{span,div}>
 *   <{span,div}>Pulled-right text</{span,div}> // optional
 * </{span,div}>
 *
 * Note: By default (no variant) this component simply displays two elements in
 * a column.
 */
.ui-component-text-group {
  display: grid;
  grid-template-areas:
    "first"
    "middle"
    "last";
  column-gap: $space-xs;
  width: 100%;
  text-align: left;

  > :first-child {
    grid-area: first;
  }

  > :nth-child(2):not(:last-child) {
    grid-area: middle;
  }

  > :last-child {
    grid-area: last;
  }

  &, &.items\:center {
    &[class*="variant"] {
      align-items: center;
    }
  }

  &.items\:top {
    align-items: flex-start;
  }

  &.overflow\:ellipsis > * {
    &, & > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.variant\:pull-right {
    grid-template-columns: 1fr auto;
    grid-template-areas: "first last";

    > :last-child:not(:first-child) {
      text-align: right;
    }
  }

  &.variant\:pull-left {
    grid-template-columns: auto 1fr;
    grid-template-areas: "first last";

    > :first-child {
      text-align: left;
    }
  }

  &.variant\:bookends {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "first middle last";

    > :first-child {
      text-align: left;
    }

    > :last-child {
      text-align: right;
    }
  }

  &.variant\:subsection {
    border: $ui-border;
    border-radius: $ui-border-radius;
    padding: $space-sm;
  }

  &.direction\:reverse {
    grid-template-areas:
      "last"
      "middle"
      "first";

    &[class*="variant"] {
      grid-template-areas: "last middle first";
    }

    &.variant\:pull-right {
      > :first-child {
        text-align: right;
      }

      > :last-child:not(:first-child) {
        text-align: left;
      }
    }
  }
}
