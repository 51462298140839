.ui-component-apexx {
  --iframe-height-sm: 396px; // Note that JS uses this value as well.
  --iframe-height-md: 448px;
  --iframe-height-lg: 475px;

  display: block;
  width: 100%;
  margin: 0 auto;
  border: none;

  // Can't get real height of cross-origin iframe so we need to do this guesswork. Heights below
  // sampled with form errors visible as it's not possible to detect when errors appear. Note that
  // these breakpoints are highly dependent on layout of payment page.
  height: var(--iframe-height-lg);

  @media screen and (min-width: 388px) {
    height: var(--iframe-height-md);
  }

  @media screen and (min-width: 592px) {
    height: var(--iframe-height-sm);
  }

  @media screen and (min-width: 768px) {
    height: var(--iframe-height-lg);
  }

  @media screen and (min-width: 851px) {
    height: var(--iframe-height-md);
  }

  @media screen and (min-width: 1200px) {
    height: var(--iframe-height-sm);
  }
}
