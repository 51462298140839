@import "../config/config";
@import "../helpers/utilities";

@import "./decorations";

/**
  * Markup:
  * <div class="utils:some-utility,another-utility"></div>
  */
[class*="utils:error"],
[class*=",error"] {
  @include error;
}

[class*="utils:margin-bottom"], [class*=",margin-bottom"] {
  margin-bottom: $space !important;
}

[class*="utils:margin-bottom-sm"], [class*=",margin-bottom-sm"] {
  margin-bottom: $space-sm !important;
}

[class*="utils:nowrap"], [class*=",nowrap"] {
  white-space: nowrap;
}

[class*="utils:overflow-hidden"], [class*=",overflow-hidden"] {
  overflow: hidden;
}

[class*="utils:sr-only"], [class*=",sr-only"] {
  @include sr-only;
}

[class*="utils:text-center"],
[class*=",text-center"] {
  text-align: center;
}

[class*="utils:text-compact"], [class*=",text-compact"] {
  line-height: 1;
}

[class*="utils:text-disabled"],
[class*=",text-disabled"] {
  opacity: $ui-opacity-disabled;
}

[class*="utils:text-error"],
[class*=",text-error"] {
  @include text-error;
}

[class*="utils:text-lg"], [class*=",text-lg"] {
  font-size: $base-font * $scale;
  font-weight: 400;
}

[class*="utils:text-link"],
[class*=",text-link"] {
  @include text-link;
}

[class*="utils:text-muted"],
[class*=",text-muted"] {
  @include text-muted;
}

[class*="utils:text-small"], [class*=",text-small"] {
  @include text-small;
}

[class*="utils:text-strikethrough"], [class*=",text-strikethrough"] {
  text-decoration: line-through;
}

[class*="utils:text-strong"], [class*=",text-strong"] {
  font-weight: 700 !important;
  opacity: 1 !important;
}

[class*="utils:text-truncate"],
[class*=",text-truncate"] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

[class*="utils:text-highlight"],
[class*=",text-highlight"] {
  color: $sb-green;
}

[class*="utils:text-pre"] {
  white-space: pre;
}
