@use "sass:math";

@import "config/config";
@import "helpers/units"; // `em` function

/**
 * Markup:
 * <span class="ui-component-tag
 *              theme:{default*, primary, info, alert, warning, offer, premium}
 *              variant:{pill}">
 *   Tag text
 * </span>
 */
.ui-component-tag {
  --bg-color: #{$grey-100};
  --color: #{$black};

  display: inline-flex;
  column-gap: $space-3xs;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 1px;
  line-height: 1rem;
  transition: all .2s;
  padding: calc(var(--space-3xs) / var(--space-scale)) var(--space-3xs);
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  font-size: $base-font * math.div(3, 4);
  background-color: var(--bg-color);
  color: var(--color);

  &.variant\:pill {
    border-radius: 2em;
    background-color: var(--color);
    color: #fff;
    text-transform: uppercase;
  }

  &.theme\:primary {
    --bg-color: #{$sb-green-100};
    --color: #{$sb-green-500};
  }

  &.theme\:info {
    --bg-color: #{$sb-blue-100};
    --color: #{$sb-blue};
  }

  &.theme\:alert {
    --bg-color: #{$sb-red-100};
    --color: #{$sb-red};
  }

  &.theme\:warning {
    --bg-color: #{$sb-orange-100};
    --color: #{$sb-orange-500};
  }

  &.theme\:offer {
    --bg-color: #{$sb-purple-100};
    --color: #{$sb-purple-500};
  }

  &.theme\:premium {
    --bg-color: #{$sb-gold-100};
    --color: #{$sb-gold-500};
  }
}
