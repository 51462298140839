@use "sass:math";

@import "./inuit";

// Logo
$logo-height: 75px;
$logo-width: 250px;

// Typography
$base-font-size: $inuit-base-font-size;
$base-font-size--mobile: 14px;
$base-line-height: $inuit-base-line-height;
$base-line-height-factor: math.div($base-line-height, $base-font-size);
$perfect-fourth: 1.333;

// Spacing
$spacing-unit: $inuit-base-spacing-unit;
$spacing-unit--tiny: $inuit-base-spacing-unit--tiny;
$spacing-unit--small: $inuit-base-spacing-unit--small;
$spacing-unit--large: $inuit-base-spacing-unit--large;
$spacing-unit--huge: $inuit-base-spacing-unit--huge;

// Animations and transitions
$transition-duration--short: .25s; // See webpack/src/components/elm/Utils/CssConstants.elm
