@import "config/config";
@import "helpers/animations";

/**
  * Markup:
  * <{ul,dl} class="ui-component-accordion
  *                 bg:{white*,grey}"
  *                 data-auto-collapse>
  *   <{li,div} class="accordion-item {--active}">
  *     <{div,dt}>
  *       Item summary here
  *       <i class="far fa-fw fa-chevron-down" aria-hidden="true"></i>
  *     </{div,dt}>
  *
  *     <{div,dd}>
  *       <div><div>Item description here (hidden by default)</div></div>
  *     </{div,dd}>
  *   </{li,div}>
  * </{ul,dl}>
  */
.ui-component-accordion {
  display: block;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  text-align: left;

  .accordion-item {
    width: 100%;
    color: $black;
    background-color: var(--accordion-bg, #fff);
    border: $ui-border;
    border-radius: $ui-border-radius;
    box-shadow: $ui-box-shadow;

    + .accordion-item {
      margin-top: $space-sm;
    }

    > :first-child:not(:last-child) { // item summary e.g. `dt`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $space-sm $space;
      margin: 0;
      cursor: pointer;
      user-select: none; // prevent accidental text selection when toggling

      i.fa-chevron-down { // accordion toggle icon
        margin-left: $space-sm;
        transition: transform .2s ease-out;
      }
    }

    > :last-child { // hidden item description e.g. `dd`
      display: grid;
      grid-template-rows: 0fr;
      margin: 0 $space;
      border-top: $ui-border;
      transition: grid-template-rows .2s ease-out;
      animation: fadeOut .2s forwards;

      > :first-child {
        overflow: hidden;

        > :first-child {
          padding: var(--space-sm) 0;
        }
      }
    }

    > * > :last-child { // last child of both item summary and item description
      margin-bottom: 0;
    }

    &.--active {
      > :first-child:not(:last-child) i.fa-chevron-down {
        transform: rotate(180deg);
      }

      > :last-child {
        grid-template-rows: 1fr;
        animation: fadeIn .6s forwards;
      }
    }
  }

  &.bg\:white {
    --accordion-bg: #fff;
  }

  &.bg\:grey {
    --accordion-bg: #{$grey-100};
  }

  &.theme\:info {
    > * {
      background-color: $sb-blue-100;
      border-color: $sb-blue-200;
      box-shadow: none;

      > :last-child {
        border-color: $sb-blue-200;
      }

      a {
        color: $sb-blue;
      }
    }
  }
}
