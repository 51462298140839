@use "sass:color";

// Colour definitions

$black: #504F4F;

$grey--lightest: #F6F7F9;
$grey--lighter: #EEF0F4;
$grey--light: #CDD3DD;
$grey: #9EA8B9;
$grey--dark: #737B89;
$grey--darker: #4A515B;
$grey--darkest: #272A2F;

$purple: #7d7dad;
$purple--dark: color.adjust($purple, $lightness: -15%);
$purple--light: color.adjust($purple, $lightness: 15%);
$purple--extra-light: color.adjust($purple, $lightness: 30%);

$green--extra-light: #EFF8F7;
$green--lightest: #E1F3F2;
$green--lighter: #6DC5BF;
$green--light: #41A49D;
$green: #2b8281;
$green--dark: #1F4A4B;
$green--darker: #152728;

$blue--extra-light: #EBF1FF;
$blue--light: #BACAEF;
$blue: #0D48A5;

$red--light: #f7ddd9;
$red: #e8554e;
$red--dark: color.adjust($red, $lightness: -15%);

$yellow: #f3be31;

// Premium colours

$gold--lightest: #f5efe8;
$gold: #a99255;
$gold--dark: color.adjust($gold, $lightness: -10%);
$gold--darker: #6D5B3C;

$color-premium: #4f4f4f;

// Semantic colours
$color-text: $black;
$color-bg: $grey--lightest;

$color-primary: $green;

$color-links: $green;
$color-links--hover: $green--dark;
$color-links--alt: $green;
$color-links--alt--hover: $green--dark;

$colour-links--secondary: #fff;
$colour-links--secondary-hover: $green;

// Bootstrap
$brand-color: $green;
$link-color: $color-links;
$link-hover-color: $color-links--hover;
