@import "config/config";
@import "helpers/fontawesome";
@import "helpers/forms";

@mixin input-selector {
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]),
  textarea,
  select {
    @content;
  }
}

@mixin input-active-floating-label {
  z-index: 1; // keep above input to avoid e.g. opacity change for disabled inputs with content
  top: 0;
  transform: translate($space-xxs, -60%);
  padding: calc(var(--space-3xs) / 2);
  background-color: $grey-100;
  border-radius: $ui-border-radius;
  font-size: 13px;
  line-height: 1;

  &::before {
    display: none;
  }
}

/**
 * Markup:
 * <label class="ui-component-input
 *               width:{full*, grow, auto}
 *               suffix:{default*, none} // `*` is default for required inputs
 *               label:{auto*, hidden} // accessibly hide label text
 *               icon:{none*, prefix} // does not apply to `textarea`
 *               variant:{placeholder-label, floating-label} // `placeholder-label` does not apply to `textarea`
 *               required<false*, true> // corresponds to `<input required>`
 *               invalid<false*, true>">
 *
 *   <i class="far fa-fw fa-icon" aria-hidden="true"></i> // optional icon
 *
 *   <input type="text"></input> // or `textarea` or `select`
 *
 *   <span class="input-label">Label text</span>
 *
 *   <i class="far fa-fw fa-chevron-down" aria-hidden="true"></i> // chevron for `select`
 *
 *   <button type="button">
 *     <i class="far fa-fw fa-xmark" aria-hidden="true"></i> // "x" icon to reset input
 *   </button>
 *
 *   <span class="input-hint"></span> // optional hint text
 *   <span class="utils:error"></span> // optional error message
 * </label>
 *
 * Note[0]: The `width` attribute only has an effect within the context of a
 * form or input-group (see helpers/forms for width mixins).
 *
 * Note[1]: An optional convenience class `ui-component-select` is included for `select`
 * elements, and is required for `variant:floating-label`.
 *
 * Note[2]: For `variant:floating-label`, use the `:placeholder-shown` pseudo-class to detect
 * when an input is filled, since `:blank` pseudo-class is still experimental. This requires
 * adding a `placeholder=" "` attribute to optional inputs that use this variant (required inputs
 * use the `:valid` pseudo-class and don't need the `placeholder` attr). The `has-input` class is
 * available for more complex or nested inputs (e.g. datepickers).
 */
.ui-component-input,
.ui-component-select {
  position: relative; // for optional absolute icon
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-bottom: 0;
  font-size: 16px; // can't use 1rem because body font-size: 14px on small screens
  font-weight: 700;
  line-height: $baseline;
  @include forms--wrapped-widths;

  .input-label {
    white-space: nowrap;
  }

  &:not(.suffix\:none) {
    &.required, [required] ~ {
      .input-label::after {
        @include forms--required-suffix;
      }
    }

    &.disabled, [disabled] ~ {
      .input-label::after {
        @include forms--required-suffix("\f05e"); // fa-ban
        color: $grey-300;
      }
    }

    &.readonly, [readonly] ~ {
      .input-label::after {
        @include forms--required-suffix("\f023"); // fa-lock
        color: $grey-300;
      }
    }
  }

  &.label\:hidden .input-label {
    @include sr-only;
  }

  input, textarea, select {
    @include forms--input-appearance;

    & {
      min-width: 100%;
    }
  }

  select {
    padding-right: calc(#{$fa-fw-width} + #{$space-sm});
    cursor: pointer;

    & ~ i {
      left: auto;
      right: $space-xxs;
      color: $black;
    }
  }

  > i, // child combinator to prevent inadvertent effects on datepickers
  > button:has(.fa-xmark) {
    pointer-events: none;
    position: absolute;
    top: calc((var(--space-scale) * 2 + var(--space-2xs) * 2 - 2px) * 0.5); // half height of the control, including label text
    left: var(--space-2xs);
    transform: translateY(50%);
    line-height: 1rem;
    color: $sb-green;

    .theme\:premium & {
      color: $sb-gold;
    }
  }

  // "x" icon to reset input
  > button:has(.fa-xmark) {
    left: auto;
    right: var(--space-2xs);
    line-height: 1;
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    pointer-events: auto;
  }

  &:is(
    .variant\:floating-label,
    .variant\:placeholder-label,
    .label\:hidden
  ) > :is(
    i,
    button:has(.fa-xmark)
  ) {
    top: 0;
    transform: translateY(100%);
  }

  > i ~ input,
  > i ~ select,
  &.icon\:prefix input { // explicit `icon:prefix` selector for things like `elm-datepicker` where `input` is wrapped in a `div` so `i ~ input` doesn't work
    padding-left: calc(#{$fa-fw-width} + #{$space-xs});
  }

  .utils\:error[hidden] {
    display: none !important;
  }

  &.invalid {
    input, textarea, select {
      border-color: $sb-red;
    }

    // On mobile devices (iOS), the browser's default focus ring overlaps with the error message, potentially obstructing its visibility.
    @media screen and (max-width: 600px) {
      input, textarea, select {
        outline: none;
        appearance: none;
        -webkit-appearance: none; /* For Safari and Chrome on iOS */
      }
    }
  }

  .input-hint {
    margin-top: 2px;
    @include text-small;
    @include text-muted;
  }

  &.variant\:placeholder-label {
    input::placeholder {
      font-size: 16px; // can't use 1rem because body font-size: 14px on small screens
      color: $grey-300;
      opacity: 1;
    }

    .input-label {
      @include sr-only;
    }
  }

  &.variant\:floating-label {
    .input-label {
      display: flex;
      align-items: center;
      position: absolute;
      top: $space-xxs;
      left: 0;
      padding: 0 $space-xxs;
      transition: all .15s ease-out;
      font-weight: 300;
      color: $grey-300;
      pointer-events: none; // so browsers' autofill still works

      &::before {
        display: inline-block;
        content: "...";
        order: 1;
      }

      &::after {
        order: 2;
      }
    }

    i ~ .input-label,
    &.icon\:prefix .input-label {
      padding-left: calc(#{$fa-fw-width} + #{$space-sm});
    }

    &:focus-within,
    &.has-input,
    &.ui-component-select { // `select` elements always have content
      .input-label {
        @include input-active-floating-label;
      }
    }

    [required]:valid,
    [placeholder]:not(:placeholder-shown), // see Note[2]
    [readonly][value],
    [disabled][value],
    :autofill,
    :-webkit-autofill {
      ~ .input-label {
        @include input-active-floating-label;
      }
    }
  }
}
