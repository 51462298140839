@use "sass:math";

@import "config/config";
@import "helpers/media-queries";

@mixin layout--columns($breakpoint, $columns) {
  @include mq($breakpoint) {
    > * {
      @if type-of($columns) == list {
        @for $i from 1 through length($columns) {
          &:nth-child(#{$i}) {
            width: calc(100% * #{nth($columns, $i)} - 2 * var(--gutter));
            flex-basis: auto;
          }
        }
      } @else {
        width: calc(100% / #{$columns} - 2 * var(--gutter));
        flex-basis: auto;
      }
    }
  }
}

@mixin layout($gutter-size: md) {
  --gutter: var(--space-lg);

  @if $gutter-size == none {
    --gutter: 0rem;
  } @else if $gutter-size == sm {
    --gutter: var(--space-xs);
  } @else if $gutter-size == lg {
    --gutter: var(--space-3xl);
  }

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gutter);
  margin: 0;
  padding-left: 0; // reset `ul` or `ol` style

  > * {
    flex: 1 1 auto;
    max-width: 100%;
    list-style: none; // reset `li` style
  }

  // Two columns
  &.columns\:2,
  &.tablet-portrait-up\:2 {
    @include layout--columns(tablet-portrait-up, 2);
  }

  &.tablet-portrait\:2 {
    @include layout--columns(tablet-portrait, 2);
  }

  &.tablet-portrait-up\:2 {
    @include layout--columns(tablet-portrait-up, 2);
  }

  &.desktop\:2 {
    @include layout--columns(desktop, 2);
  }

  &.desktop\:2-1 {
    @include layout--columns(desktop, (math.div(2, 3), math.div(1, 3)));
  }

  // Three columns
  &.columns\:3,
  &.tablet-landscape-up\:3 {
    @include layout--columns(tablet-landscape-up, 3);
  }

  // Four columns
  &.columns\:4,
  &.tablet-landscape-up\:4 {
    @include layout--columns(tablet-landscape-up, 4);
  }

  // Five columns
  &.columns\:5,
  &.desktop\:5 {
    @include layout--columns(desktop, 5);
  }

  &.columns\:scroll,
  &.columns\:scroll-always {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 0 $space $space; // overflow-x above causes clipping on children with box-shadow, etc
    margin: calc(-1 * var(--space));
    margin-top: 0;

    &[class*=bg\:] {
      margin-right: calc(-1 * (var(--gutter) + var(--space)));

      &::after {
        display: block;
        content: "";
        position: sticky;
        top: 0;
        bottom: 0;
        right: 0;
        width: calc(var(--gutter) * 1.5);
        margin-left: calc(var(--gutter) * -1.5);
        flex-shrink: 0;
        align-self: stretch;
        background: linear-gradient(-90deg, #fff 50%, rgba(#fff, 0) 100%);
      }
    }

    &.bg\:white::after {
      background: linear-gradient(-90deg, #fff 50%, rgba(#fff, 0) 100%);
    }

    &.bg\:grey::after {
      background: linear-gradient(-90deg, $grey-100 50%, rgba($grey-100, 0) 100%);
    }

    &.bg\:dark::after {
      background: linear-gradient(-90deg, $grey-400 50%, rgba($grey-400, 0) 100%);
    }

    > * {
      width: auto;
      flex-shrink: 0;

      &:last-child {
        margin-right: calc(var(--gutter) * 0.5);

        @include tablet-landscape-up {
          margin-right: 0;
        }
      }
    }
  }

  &.columns\:scroll {
    @include desktop {
      flex-wrap: wrap;

      &::after {
        display: none;
      }
    }
  }
}

/**
  * Markup:
  * <{div,ul,ol} class="ui-component-layout
  *                     columns:{auto*, n, scroll, scroll-always}
  *                     fade:{default*, none} // for scrolling layout
  *                     {breakpoint}:{n}
  *                     gutter:{none, sm, md*, lg}
  *                     vertical-gutter:{default*, none, sm, md, lg}
  *                     bg:{white*, grey, dark}
  *                     variant:{search}">
  * </{div,ul,ol}>
  *
  * Note: When no column count is specified (`columns:n`), a breakpoint class
  * with `n` column-count is required, or a flex-basis should be set on the
  * child flex items where they're declared, otherwise they will expand to the
  * width of their content.
  *
  * Note: `columns:scroll` reverts to `columns:auto` on desktop; use
  * `columns:scroll-always` to keep scrolling layout on larger screens.
  */
.ui-component-layout {
  @include layout(md);

  &.gutter\:none {
    @include layout(none);
  }

  &.gutter\:sm {
    @include layout(sm);
  }

  &.gutter\:lg {
    @include layout(lg);
  }

  &.vertical-gutter\:sm {
    row-gap: $space-sm;
  }

  &.variant\:search {
    --gutter: var(--space-3xl);
    gap: unset;
    column-gap: var(--gutter);

    body.--map-open & {
      // Map
      > :last-child {
        flex-basis: 100%;
      }
    }

    @include container-style("--screen-desktop-search", true) {
      > * {
        flex-basis: auto;
        width: calc(var(--width) - var(--gutter));
        height: 100%;
      }

      // Filters
      > :first-child {
        --width: 25%;
      }

      // Results
      > :nth-child(2) {
        --width: 75%;
      }

      // Map
      > :last-child {
        --width: var(--gutter);
      }

      body.--map-open & {
        flex-wrap: nowrap;
        column-gap: 0;

        > * {
          flex-grow: 0;
          flex-basis: var(--width);
          min-width: var(--width);
          padding-left: var(--space);
          padding-right: var(--space);
        }

        // Filters
        > :first-child {
          --width: 350px;
        }

        // Results
        > :nth-child(2) {
          --width: 450px;
        }

        // Map
        > :last-child {
          flex-grow: 1;
          flex-basis: auto;
          min-width: 0;
          padding: 0;
        }
      }
    }
  }
}
