@import "config/config";
@import "helpers/fontawesome"; // $fa-fw-width
@import "helpers/media-queries";

/**
 * Markup:
 * <article class="ui-component-card
 *                 container:{default*, none} // No effect when `layout:inline`
 *                 align:{left*, center}
 *                 image-aspect:{default*, wide, square}
 *                 image-bleed:{default*, none}
 *                 spacing:{default*, compact}
 *                 size:{default*, sm}
 *                 width:{sm, md*, lg}
 *                 bg:{white*, grey}
 *                 border:{default*, grey}
 *                 header:{default*, raised} // No effect when `layout:inline`
 *                 clickable:{default*, card, image}">
 *
 *   <div data-img="bg.jpg"> // background image...
 *     <img> // ...or put image tag inside container with empty `data-img` attr
 *     <div class="ui-component-carousel"></div> // ...or carousel instead of image
 *
 *     <{a,button}></{a,button}> // optional link or button over image
 *
 *     <button class="info-icon">
 *       <span><span><i class="fa-circle-info"></i> More info</span></span>
 *     <button>
 *   </div>
 *
 *   <header> // optional header
 *     <h*>Heading</h*>
 *     <h*>Subheading</h*>
 *   </header>
 *
 *   <p></p> // optional content
 *
 *   <{a,button}></{a,button}> // optional CTA
 *
 *   <footer></footer> // optional footer (e.g. meta content)
 * </article>
 *
 * <article class="ui-component-card layout:inline">
 *   <div data-img></div> // optional
 *   <div> // all other content must be inside this wrapper `div`
 *     <aside></aside>
 *     <section>
 *       <header></header>
 *       <section></section>
 *     </section>
 *     <footer></footer>
 *   </div>
 * </article>
 *
 * Note: The `layout:inline` option requires max two children: the `data-img` element
 * (optional) and another `div` containing all other content.
 *
 * Note: Use the `width` class to set a default width for groups of cards in a
 * layout component, useful when `columns:auto` or `columns:scroll`. It will
 * override any other `columns:n` class on the layout and allow them to wrap
 * (and expand) automatically as the screen size changes.
 */
.ui-component-card {
  --card-space: var(--space);

  overflow: hidden; // For border-radius clipping
  display: flex;
  flex-direction: column;
  align-self: stretch; // ensure cards with differing content all have same height in a row
  align-items: flex-start;
  gap: var(--card-space);
  padding: var(--card-space);
  text-align: left;
  color: $black;
  background-color: #fff;
  border-radius: $ui-border-radius-lg;
  box-shadow: $ui-box-shadow-heavy;

  > :not(a):not(button) {
    width: 100%;
  }

  > :last-child:not(:first-child):not(p):not(ul) {
    margin-top: auto; // push last flex element to bottom of container
  }

  > :not([data-img]) {
    margin-bottom: 0 !important; // using `gap` for spacing so remove margins
  }

  > [data-img] {
    --image-aspect: 1.5;
    --image-offset: var(--card-space);

    position: relative; // for optional absolute link/button
    overflow: hidden; // for border-radius clipping when containing optional `img`
    margin: calc(-1 * var(--card-space)) calc(-1 * var(--card-space)) 0 calc(-1 * var(--card-space));
    background-size: cover;
    background-position: 50% 50%;
    aspect-ratio: var(--image-aspect);
    object-fit: cover;
    width: calc(100% + 2 * var(--image-offset));

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    > a, > button {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      line-height: 1;
      padding: $space-3xs $space-3xs 100% 100%;
      color: $sb-green;
      background: transparent;
      border: none;
      white-space: nowrap;
      cursor: pointer;
    }

    > button.info-icon {
      outline: none;

      > span {
        display: inline-block;
        overflow: hidden;
        border-radius: $ui-border-radius;

        > span {
          display: block;
          padding: $space-3xs;
          border-radius: $ui-border-radius;
          background: rgba($black, .5);
          color: #fff;
          font-size: 1rem;
          transform: translateX(calc(100% - #{$fa-fw-width} - var(--space-3xs) * 2));
          transition: transform .2s;

          > .fa-fw {
            margin-right: $space-3xs;
          }
        }
      }

      &:hover > span > span {
        transform: none;
      }
    }
  }

  &.image-aspect\:wide > [data-img] {
    --image-aspect: 2;
    --image-offset: var(--card-space);
  }

  &.image-aspect\:square > [data-img] {
    --image-aspect: 1;
    --image-offset: var(--card-space);
  }

  &.image-bleed\:none > [data-img] {
    --image-offset: 0rem;

    margin: 0;
    border-radius: $ui-border-radius-lg;
  }

  &.container\:none {
    padding: 0;

    > [data-img] {
      margin: 0;
    }
  }

  &.layout\:inline {
    gap: var(--card-space);

    &:not(.image-bleed\:none) {
      gap: calc(2 * var(--card-space));

      > [data-img] {
        margin-bottom: calc(-1 * var(--card-space));
      }
    }

    > div:last-child,
    > [data-img] + div {
      gap: var(--card-space);
    }
  }

  header {
    gap: calc(var(--card-space) / (var(--space-scale) * var(--space-scale)));

    > * {
      margin-bottom: 0;
      font-weight: 700;
    }

    > :first-child:not(div) {
      font-size: $base-font * $scale;
    }

    > :nth-child(2):not(div) {
      font-size: $base-font;
    }
  }

  &.header\:raised:not(.layout\:inline) {
    > [data-img] {
      + header,
      + div > header {
        margin-top: calc(-3 * var(--card-space));
      }
    }

    header {
      position: relative; // new stacking context to keep above `[data-img]`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space-3xs);
      padding: var(--card-space);
      background-color: #fff;
      border-radius: $ui-border-radius;
      box-shadow: $ui-box-shadow;
      text-align: center;

      > :nth-child(2), > :last-child {
        font-size: initial;
        font-weight: 400;
        opacity: .8;
      }

      > :first-child {
        font-size: $base-font * $scale !important;
        font-weight: 700;
        opacity: 1;
      }

      > :nth-child(2) {
        order: -1;
      }
    }

    &.container\:none,
    &.image-bleed\:none {
      header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &:not(.bg\:grey) {
      > header:first-child,
      > div:first-child > header {
        background-color: $grey-100; // flip colour if no `[data-img]`
      }
    }
  }

  footer {
    @include mobile-max {
      text-align: center !important;
    }
  }

  &.size\:sm,
  &.spacing\:compact {
    --card-space: var(--space-xs);
  }

  &.size\:sm {
    header > :first-child:not(div) {
      font-size: $base-font * 1.155; // $scale ^ 1/2
    }

    &.header\:raised:not(.layout\:inline) {
      header > :first-child {
        font-size: $base-font;
      }
    }
  }

  &.bg\:grey {
    background-color: $grey-100;
  }

  &.border\:grey {
    box-shadow: inset 0 0 0 1px $grey-200;
  }

  &.container\:none {
    background-color: transparent;
    box-shadow: none;

    > [data-img] {
      max-width: 100%;
      //margin: 0;
      border-radius: $ui-border-radius-lg;
    }
  }

  &.align\:center {
    align-items: center;
    text-align: center;

    > [data-img] {
      align-self: normal; // override `align-items: center`
    }
  }

  &:not(.layout\:inline) {
    &.size\:sm,
    &.width\:sm {
      flex-basis: 240px;
      max-width: 360px;
    }

    &, &.width\:md {
      flex-basis: 360px;
      max-width: 480px;
    }

    &.width\:lg {
      flex-basis: 480px;
      max-width: 600px;
    }
  }

  &.layout\:inline {
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;

    > [data-img] {
      flex-basis: 25%;
      flex-grow: 1;
      align-self: stretch;
      min-width: 240px;
    }

    > div:last-child, // this selector allows omitting the optional `[data-img]` (e.g. venue page)
    > [data-img] + div { // this selector allows other (unrelated) elements to follow the `div` (e.g. search page)
      flex-grow: 2;
      flex-shrink: 1;
      align-self: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: var(--space-sm);
      container-type: inline-size; // For footer @container query

      > * {
        margin-bottom: 0 !important; // using `gap` for spacing so remove margins
      }

      // Special offers (top/bottom of card)
      > aside {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        padding: var(--space-3xs);
        color: $sb-purple-500;
        background-color: $sb-purple-100;
        border-radius: $ui-border-radius;
      }

      // Main content
      > section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--space-2xs);
        flex: 1;

        > * {
          margin: 0 !important;
        }

        > header {
          display: flex;
          flex-direction: column;
          gap: calc(var(--space-2xs) / 2) !important;
        }
      }

      // Price, CTA
      > footer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        gap: var(--space-2xs);
        flex-basis: 100%;
        margin-left: auto;
        text-align: right !important;

        .ui-component-from-price {
          text-align: left;
        }

        @container (width > 400px) {
          flex-direction: column;
          flex-basis: min-content;

          .ui-component-from-price {
            margin-left: auto;
            text-align: right;
          }
        }
      }
    }

    &:not(.align\:center) {
      > div:last-child > :last-child:not(header):not(p) {
        margin-left: auto;
      }
    }

    > div:last-child {
      flex-basis: 100%;
    }

    > [data-img] + div {
      flex-basis: 50%;
    }
  }

  // Only supports one link per card
  &.clickable\:card,
  &.clickable\:image {
    position: relative;
    overflow: hidden;

    a::after {
      z-index: 1;
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.clickable\:image {
    a::after {
      bottom: 100%;
      aspect-ratio: 1.5;
    }

    &.image-aspect\:wide a::after {
      aspect-ratio: 2;
    }

    &.image-aspect\:square a::after {
      aspect-ratio: 1;
    }
  }
}
