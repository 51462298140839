@import "./ui_components/config/config";
@import "../ui_components/helpers/media-queries";
@import "../shared/settings/global";

.sb-navbar {
  --nav-min-col-width: 170px;

  z-index: $z-index-sticky;
  position: relative;
  margin-bottom: 0;

  body.--mobile-nav-open &,
  &--open {
    z-index: $z-index-modal;
  }

  ul {
    display: flex;
    margin: 0px;
    padding: 0px;
    list-style: none;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &__main-header {
    padding: 0 var(--space-xs);
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space);
    border-bottom: 1px solid rgb(0, 0, 0, 0.03);

    @include phablet-up {
      padding-left: var(--space);
      padding-right: var(--space);
    }

    li {
      list-style: none;
    }

    > ul > li:last-child {
      margin-left: 5px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .site-logo {
      margin-right: 0;
    }
  }

  &__secondary {
    box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.1);
    height: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    a {
      text-transform: capitalize;

      color: $grey-400;
      padding: 12px;
      line-height: 26px;
      flex: 1;
      white-space: nowrap;

      @media screen and (max-width: 1024px) {
        padding-left: 8px;
        padding-right: 8px;
      }

      @media screen and (min-width: 801px) {
        font-size: 0.875rem;
      }
    }

    li ul li a {
      padding: 2px 15px;
    }

    > ul > li > a:hover {
      color: $sb-green;
    }
  }

  &__secondary &__top-level-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__secondary &__top-level-item {
    position: relative;

    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background-color: $sb-green-100;
      }
    }

    &--open > ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &--open > a {
      color: $sb-green;
    }
  }

  &__secondary &__top-level-item.sb-navbar__top-level-item--open &__dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: calc(100% - 3px);
    left: 0px;
    height: auto;
    width: max-content;
    min-width: var(--nav-min-col-width);
    background-color: white;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    z-index: 20;
    padding: $space-3xs 0;
    border-radius: 3px;
    border: 1px solid #ebebeb;

    &::before {
      content: "";
      position: absolute;
      border: 8px solid transparent;
      border-bottom-color: #ebebeb;
      top: -16px;
      left: 30px;
      right: auto;
    }
    &::after {
      content: "";
      position: absolute;
      border: 7px solid transparent;
      border-bottom-color: white;
      top: -14px;
      left: 31px;
      right: auto;
    }

    > ul {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__nav-groups-wrapper {
    padding: 10px 10px 0px;
  }

  &__top-level-item--open &__nav-groups-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__nav-groups {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 1rem;
  }

  &__nav-group {
    min-width: var(--nav-min-col-width);

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      a {
        padding: 3px !important;
      }
    }

    p {
      margin-bottom: 0px;
      position: relative;
      font-size: 85%;
      font-weight: 700;
      opacity: 0.7;
      margin-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        height: 1px;
        width: 100%;
        background-color: $sb-green;
      }
    }
  }

  &__secondary &__nav-groups-cta {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      height: 1px;
      width: 100%;
      background-color: $sb-green;
    }

    a {
      display: block;
      padding: 15px;
      line-height: 26px;
    }
  }

  &__signed-out {
    display: flex;
    white-space: nowrap;

    a {
      padding: 0px 3px;
      font-size: 0.875rem;
    }
  }

  &__burger-menu,
  &__mobile-menu {
    display: none;
  }

  &__drawer {
    display: none;
    overflow: hidden;
    max-width: 500px;
  }

  &__drawer-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(85, 85, 85, 0.8);
    transition: all 0.2s ease-out;
    z-index: -999;
    visibility: hidden;
    opacity: 0;
  }

  &__drawer-inner {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 200%;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    overflow-y: hidden;
    visibility: hidden;

    > div {
      flex: 1;
      padding: 10px 20px;
    }
  }

  &__drawer-item-image {
    background-color: $grey-100;
  }

  &__drawer--first-slide.--second-slide-opened &__drawer-inner {
    animation: animate-to-first-slide $transition-duration--short;
  }

  &__drawer--second-slide &__drawer-inner {
    left: calc(-100% - 30px);
    animation: animate-to-second-slide $transition-duration--short;
  }

  &__first-slide {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-right: 60px;
  }

  &__first-slide-items {
    flex: 1;
    margin-top: 10px;
  }

  &__first-slide-item {
    display: flex;

    a, button {
      color: $grey-400;
      display: flex;
    }

    &--only-link {
      list-style: none;
      li {
        display: flex;
      }
      li,
      a, button {
        display: block;
        flex: 1;
        padding-left: 0;
      }
    }

    &--contact-link {
      flex-direction: column;
      font-size: larger;

      > :first-child {
        font-size: larger;
        font-weight: 700;
      }

      > :last-child {
        display: inline-block;
      }

      a {
        display: inline-block;
        color: $sb-green;
        text-decoration: none;
        font-weight: 700;
      }
    }

    &--spacer {
      hr {
        width: 100%;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid $grey-200;
        padding: 0;
      }
    }

    &--link-with-image {
      position: relative;
      min-height: 100px;
      margin-bottom: 10px;
      display: flex;

      a {
        flex: 1;
        display: flex;
        align-items: flex-end;
        position: relative;
        color: white;
        font-weight: bolder;
        font-size: initial;
        height: 100px;
        padding: 10px;

        &:hover {
          color: white;
        }
      }
    }

    &:not(.sb-navbar__first-slide-item--link-with-image) {
      padding: 5px 3px;
    }

    &:not(.sb-navbar__first-slide-item--spacer):not(.sb-navbar__first-slide-item--link-with-image):not(.sb-navbar__first-slide-item--link-with-icon):not(.sb-navbar__first-slide-item--heading):hover {
      background-color: $sb-green-100;
    }
  }

  &__drawer-item-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;

    background-size: cover;
    background-position: center;
    filter: brightness(0.8);
  }

  &__drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .fa-chevron-left {
      font-size: 1.4rem;

      &::before {
        color: $sb-green;
      }
    }
  }

  &__first-slide &__drawer-header {
    min-height: 50px;
    justify-content: flex-end;
  }

  &__second-slide-image-wrap {
    position: relative;
    height: 100px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    p {
      color: white;
      font-size: larger;
      font-weight: bold;
      margin-left: 0.55rem;
      margin-bottom: 0.55rem;
    }
  }

  &__second-slide-inner {
    margin-top: 10px;
    flex: 1;
    height: calc(100% - 50px);
  }

  &__second-slide {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__second-slide-menu {
    ul {
      display: flex;
      flex-direction: column;
      li {
        margin: 7px 0px;
        a {
          color: $grey-400;
        }
      }
    }
  }

  &__second-slide-item {
    p {
      margin-bottom: 0.2rem;
      padding-bottom: 0.7rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      color: $sb-green;
      text-transform: uppercase;
      font-weight: bold;
    }

    &:not(:first-child) p {
      margin-top: 1.7rem;
    }
  }

  button {
    border: none;
    background-color: transparent;
  }

  .ui-component-notice.align\:center header ~ div p {
    text-align: left;
  }

  &__account {
    position: relative;

    ul {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      li {
        width: 100%;
        justify-content: flex-start;
        padding: $space-3xs $space-sm;

        &:not(.sb-navbar__spacer):hover {
          background-color: $sb-green-100;
        }

        a, button, form {
          flex: 1;
          justify-content: flex-start;
          width: 100%;
          padding: 0;
          text-align: left;
          font-size: 0.875rem;
          cursor: pointer;
        }
      }

      li,
      li a,
      li button {
        color: $grey-400;
      }

      hr {
        width: 100%;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid $grey-200;
        padding: 0;
        margin: 0;
      }
    }
  }

  &__account-inner {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $grey-400;
    font-size: 0.875rem;
  }

  &__account &__dropdown {
    top: calc(100% + 13px);
    left: auto;
    right: -2px;

    &::before {
      top: -16px;
      right: 15px;
      left: auto;
    }

    &::after {
      top: -14px;
      right: 16px;
      left: auto;
    }
  }

  &__account--open &__dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__account-icon {
    background: linear-gradient(180deg, $sb-green-300 0%, $sb-green-500 100%);
    width: 33px;
    height: 33px;
    font-weight: 700;
    color: white;
    border-radius: 50%;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__desktop-menu {
    gap: var(--space-2xs);

    > a {
      text-decoration: none;
    }
  }

  &:has(.sb-navbar__breadcrumbs) {
    .sb-navbar__desktop-menu {
      gap: var(--space-sm);
    }
  }

  &__breadcrumbs {
    display: flex;
    height: 100%;
    align-items: center;

    > span {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 var(--space-sm);
      font-size: 0.875rem;
      white-space: nowrap;

      &.active {
        color: $sb-green;
        font-weight: 700;
        box-shadow: 0 2px 0 0 $sb-green;
      }

      @media screen and (max-width: 1000px) {
        padding: 0 var(--space-2xs);
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: map-get($_mq-breakpoints, tablet-portrait-up)) {
    &__drawer {
      display: none;
      position: fixed;
      flex-direction: row;
      top: 0px;
      bottom: 0px;
      right: -530px;
      padding-right: 30px;
      width: calc(500px + 30px);
      max-width: calc(100% + 30px);
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: white;
      z-index: 1000;
    }

    &__drawer-wrapper--open &__drawer {
      display: flex;
      right: -30px;
      animation: open-mobile-menu $transition-duration--short;
      box-shadow: 0px 0px 8px rgb(0, 0, 0, 0.4);
    }

    &__drawer-wrapper--closed &__drawer {
      animation: close-mobile-menu $transition-duration--short;
    }

    &__drawer-wrapper--open &__drawer-inner {
      visibility: visible;
    }

    &__drawer-wrapper--closed &__drawer-inner {
      animation: animate-drawer-inner $transition-duration--short;
    }
    &__drawer-wrapper--open &__drawer-backdrop {
      z-index: 999;
      visibility: visible;
      opacity: 1;
    }

    &__secondary {
      display: none;
    }

    &__main-header {
      height: 60px;
    }

    &__account,
    &__desktop-menu {
      display: none !important;
    }

    &__burger-menu {
      display: block;
    }

    &__mobile-menu {
      display: flex;
      align-items: center;
      gap: var(--space-2xs);
    }

    &__mobile-menu &__account-icon {
      margin-left: 0px;
    }
  }
}

@keyframes open-mobile-menu {
  0% {
    right: -500px;
  }
  70% {
    right: -20px;
  }
  100% {
    right: -30px;
  }
}
@keyframes close-mobile-menu {
  0% {
    right: -30px;
  }
  50% {
    right: -20px;
  }
  100% {
    right: -530px;
  }
}

@keyframes animate-to-second-slide {
  0% {
    left: 0px;
  }
  40% {
    left: 10px;
  }
  100% {
    left: calc(-100% - 30px);
  }
}

@keyframes animate-to-first-slide {
  0% {
    left: calc(-100% - 30px);
  }
  40% {
    left: calc(-100% - 40px);
  }
  100% {
    left: 0px;
  }
}

@keyframes animate-drawer-inner {
  0% {
    visibility: visible;
  }
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

body.--mobile-nav-open {
  overflow: hidden;
}
