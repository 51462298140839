@use "sass:color";

@import "config/config";
@import "helpers/fontawesome";
@import "helpers/forms";
@import "helpers/media-queries";
@import "helpers/utilities";

$button--themes: (
  primary: $sb-green $sb-green-100,
  alternate: $sb-purple $sb-purple-100,
  premium: $sb-gold $sb-gold-100,
  dark: $sb-black-new $sb-black-new
);

@mixin button--theme($color, $light-color) {
  &:not(.variant\:link):not(.variant\:dismiss):not(.variant\:input) {
    color: #fff;
    background-color: $color;
    border: 1px solid $color;

    &:hover, &:focus, &:active {
      box-shadow: $ui-box-shadow-heavy;
    }

    &:hover, &:focus {
      background-color: color.adjust($color, $lightness: -5%);
    }

    &:active {
      background-color: color.adjust($color, $lightness: -10%);
    }

    &.variant\:inverted {
      color: $color;
      background-color: $light-color;
      border-color: transparent;

      &:hover, &:focus, &:active {
        color: color.adjust($color, $lightness: -5%);
      }

      &:hover, &:focus {
        background-color: color.adjust($light-color, $lightness: -5%);
      }

      &:active {
        background-color: color.adjust($light-color, $lightness: -10%);
      }
    }

    &.variant\:outline {
      color: $color;
      background-color: transparent;

      &:hover, &:focus, &:active {
        color: #fff;
      }

      &:hover, &:focus {
        background-color: color.adjust($color, $lightness: -5%);
      }

      &:active {
        background-color: color.adjust($color, $lightness: -10%);
      }
    }

  }

  &.variant\:link {
    text-align: initial;
    white-space: normal;
  }

  &.variant\:link,
  &.variant\:dismiss {
    color: $color;
    background: transparent;
    border: none;

    &:hover, &:focus, &:active {
      color: color.adjust($color, $lightness: -5%);
      background: transparent;
      box-shadow: none;
    }
  }
}

@mixin button--theme-selectors($theme-name, $color, $light-color) {
  a.ui-component-button.theme\:#{$theme-name}, // extra specificity...
  button.ui-component-button.theme\:#{$theme-name},
  input[type="submit"].ui-component-button.theme\:#{$theme-name},
  .theme\:#{$theme-name} .ui-component-button { // ...to override cascading themes
    @include button--theme($color, $light-color);
  }
}

/**
  * Markup:
  * <{a,button} class="ui-component-button
  *                    theme:{primary*, alternate, premium, dark}
  *                    variant:{default*, inverted, outline, link, dismiss, input, pill}
  *                    icon:{none*, left, right}
  *                    subtext:{none*, left, right}">
  * </{a,button}>
  *
  * <{a,button} class="ui-component-button icon:right">
  *   Button text
  *   <i class="far fa-arrow-right" aria-hidden="true"></i>
  * </{a,button}>
  *
  * <{a,button} class="ui-component-button subtext:left">
  *   <span>Button subtext</span>
  *   <span>Button text</span>
  * </{a,button}>
  *
  * Note: `icon` and `subtext` can be used together if markup structured as
  * above (just add icon before/after the subtext `span`).
  *
  * Note: Link-style buttons with icons should use the `variant:link` class but
  * omit the `icon:*` class (just add the icon markup as above).
  */
a.ui-component-button,
button.ui-component-button,
input[type="submit"].ui-component-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all .15s;
  @include user-select-none;
  @include button--theme(
    nth(map-get($button--themes, primary), 1),
    nth(map-get($button--themes, primary), 2)
  );

  &:not(.variant\:dismiss):not(.variant\:link):not(.variant\:input) { // this has added bonus of helping override any cascading styles on `a` declared elsewhere
    justify-content: center;
    width: 100%;
    padding: $space-xxs $space;
    text-decoration: none;
    text-align: center;
    font-size: 1rem;
    line-height: $baseline;
    font-weight: 700;
    border-radius: $ui-border-radius;

    @include phablet-up {
      width: auto;
    }
  }

  &.variant\:fit-content {
    width: fit-content;
  }

  &.variant\:link,
  &.variant\:dismiss {
    display: inline-block;
    padding: 0;
    line-height: inherit;
    text-decoration: underline;
    outline: none;

    i {
      color: inherit;
    }
  }

  &.variant\:dismiss {
    line-height: 1;

    &::after {
      @include fa-pseudo("\f00d"); // fa-xmark
      text-decoration: none;
    }

    > span {
      @include sr-only; // inclusively hide button text
    }
  }

  &.variant\:input {
    @include forms--input-appearance;

    & {
      display: inline-flex;
      text-align: left;
      padding-top: $space-sm;
      padding-bottom: $space-sm;
      transition: none;
    }

    i {
      color: $sb-green !important;
    }
  }

  &.variant\:pill {
    border-radius: 100px !important;
  }

  &.icon\:left,
  &.icon\:right {
    align-items: baseline;

    i {
      color: inherit;
    }
  }

  &.icon\:left i:first-child {
    margin-right: 1ch;
  }

  &.icon\:right {
    padding-right: 0 !important;

    i:last-child {
      margin-left: calc(var(--space) - var(--space-3xs));
      margin-right: $space-xxs;
    }

    > :nth-child(2) { // optional subtext, or i:last-child
      margin-left: auto;
    }
  }

  &.subtext\:left,
  &.subtext\:right {
    align-items: center;
    justify-content: center;

    > span:nth-of-type(1) {
      margin-right: $space-3xs;
    }
  }

  &.subtext\:left > span:nth-of-type(1),
  &.subtext\:right > span:nth-of-type(2) {
    opacity: .6;
    font-weight: 400;
  }

  &[disabled] {
    @include forms--input-disabled;
  }

  @each $theme in (info $sb-blue, alert $sb-red) {
    .theme\:#{nth($theme, 1)} &.variant\:link { // no `info` or `alert` theme for buttons, only links
      color: nth($theme, 2);
      background: transparent;
      border: none;

      &:hover, &:focus, &:active {
        color: color.adjust(nth($theme, 2), $lightness: -5%);
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

@each $theme in map-keys($button--themes) {
  $colors: map-get($button--themes, $theme);
  @include button--theme-selectors($theme, nth($colors, 1), nth($colors, 2));
}
