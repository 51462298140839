$font-display: swap;
$font-bucket: "https://storage.googleapis.com/ygt-fonts";
$names: (
  "Lato-Regular": "Lato Regular",
  "Lato-Italic": "Lato Italic",
  "Lato-Bold": "Lato Bold",
  "Lato-BoldItalic": "Lato Bold Italic"
);
$ranges: (
  unicode-range: (
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD
  ),
  unicode-range-ext: (
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF
  )
);

@each $name, $alternate in $names {
  @each $rangename, $range in $ranges {
    $extended: if(str-index($rangename, "-ext"), "-LatinExtended", "");

    @font-face {
      font-family: "Lato";
      font-style: if(str-index($name, "Italic"), italic, normal);
      font-weight: if(str-index($name, "Bold"), 700, 400);
      font-display: $font-display;
      src: local("#{$name}"),
          local("#{$alternate}"),
          url("#{$font-bucket}/#{$name}#{$extended}.woff2") format("woff2");
      unicode-range: $range;
    }
  }
}
