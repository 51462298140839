@import "../config/config";
@import "fontawesome";

@mixin error {
  display: inline-flex;
  align-items: baseline;
  @include text-error;

  .theme\:alert & {
    color: initial; // theme:alert has red background so don't want red font as well
  }

  &::before {
    @include fa-pseudo("\f06a"); // fa-exclamation-circle
    margin-right: 1ch;
    color: $sb-red;
  }
}

@mixin text-error {
  font-weight: 400;
  color: $sb-red;
}

@mixin text-link {
  font-weight: 400;
  color: $sb-green;
  text-decoration: underline;
  cursor: pointer;

  &.theme\:premium,
  .theme\:premium & {
    color: $sb-gold;
  }
}

@mixin text-muted {
  font-weight: 400;
  opacity: $ui-opacity-anc;
}

@mixin text-small {
  font-size: .85rem;
  font-weight: 400;
}

@mixin sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
}

@mixin user-select-none {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
