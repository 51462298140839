@import "config/config";
@import "helpers/background"; // background-extend
@import "helpers/forms"; // forms--input-appearance
@import "helpers/wrapper";

/**
 * Markup:
 * <div class="ui-component-search
 *             variant:{home, ...}">
 * // Desktop
 *   <form class="ui-component-form"></form>
 *
 * // Mobile
 *   <div>
 *     <div class="search-buttons">
 *       <button></button> // search "input"
 *       <button class="ui-component-button variant:link"></button> // filters link
 *     </div>
 *     <div class="ui-component-modal">
 *       <form class="ui-component-form"></form>
 *     </div>
 *   </div>
 * </div>
 */
.ui-component-search {
  &:not(.variant\:home) {
    background-color: #fff;
    border-radius: 0;
    @include wrapper-with-padding(lg);

    @include container-style("--screen-desktop-search", true) {
      background-color: transparent;
    }
  }

  .search-buttons {
    --button-padding-x: var(--space-sm);
    --button-padding-y: var(--space-2xs);

    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: calc(2 * (var(--button-padding-y) + $baseline)); // padding plus two lines

    > :first-child { // search "input"
      @include forms--input-appearance;

      & {
        display: inline-flex;
        align-items: baseline;
        padding-left: 0;
        padding-right: 0;
        border: none;
        box-shadow: none;
        text-align: left;
        outline: none;
        transition: none;
        cursor: pointer;
      }

      i {
        color: $sb-green !important;
      }
    }

    > :last-child:not(:first-child) { // filters link
      align-self: stretch;
      flex-shrink: 0;
      padding-left: var(--button-padding-x);
      margin: var(--button-padding-y) 0 var(--button-padding-y) var(--button-padding-x);
      outline: none;
      border-left: 1px solid $grey-200;
      text-decoration: none;

      > sup {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $sb-red;
        font-size: 10px;
        line-height: 1;
        padding: 1.5ex;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: -1em;
      }
    }

    > * {
      padding-top: var(--button-padding-y);
      padding-bottom: var(--button-padding-y);
    }
  }

  .ui-component-form {
    position: relative; // for background-extend
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: $space-xs;
    row-gap: $space-sm;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: $space;
    padding-bottom: $space;
    text-align: left;

    &::before {
      background-color: $grey-100;
      @include background-extend;
    }

    body.--map-open &::before {
      background-color: #fff;
    }

    > * {
      margin: 0;
    }

    > :not(button) {
      flex: 1 1 200px;
    }

    > button {
      flex: 1 1 auto;
    }

    .search-location {
      flex: 1 1 250px;

      @include container-style("--screen-desktop-search", false) {
        flex: 1 1 100%;
      }

      @media screen and (min-width: sb-rem(877px)) {
        flex-basis: 300px;
      }

      @media screen and (min-width: sb-rem(1298px)) {
        flex-basis: 600px;
      }
    }
  }

  .ui-component-modal {
    .ui-component-hide-and-seek & {
      height: 100vh; // global search is inside sticky container
    }

    > [tabindex] {
      overflow: visible; // `overflow-y` on its own doesn't work because of `overflow-x: hidden` in modal component
      max-width: none;
      padding: var(--space-lg) var(--space);
    }

    .ui-component-form {
      padding: 0;
      background-color: transparent;

      &::before {
        display: none;
      }
    }

    &.reveal\:slide-down { // search modal
      > [tabindex] {
        margin: 0;
        background-color: $sb-green-100;
        border-radius: 0;
      }
    }

    &.reveal\:slide-left { // filters modal
      .ui-component-hide-and-seek & {
        > [tabindex] {
          height: auto;
        }
      }

      .filter-panel { // override old styles
        margin: 0;
        padding: 0;
      }
    }
  }

  &.variant\:home {
    @include tablet-portrait-up {
      margin: 0 var(--space);
    }

    > div > div {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
      background-color: $grey-100;
      border-radius: $ui-border-radius-lg;
      @include wrapper(lg);
    }

    .ui-component-form {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);

      @include container-style("--screen-desktop-search", true) {
        row-gap: var(--space-2xs);
      }


      &::before {
        display: none;
      }

      @include mobile-max {
        > label {
          flex: 1 1 200px;
        }
      }
    }

    .trustpilot-widget {
      flex-basis: 100%;
    }
  }
}
