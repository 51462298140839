@use "sass:math";

@import "config/config";
@import "helpers/units";

$voucher--max-card-width: sb-rem(400px);
$voucher--aspect-ratio: math.div(1, 1.586) * 100%; // ID-1 aspect ratio ~ 3 3/8" x 2 1/8"

/**
  * Markup:
  * <div class="ui-component-voucher
  *             bg:{none*, envelope}">
  *   <div>
  *     <div>
  *       <span>Gift voucher</span>
  *       <span class="utils:wave">£150</span>
  *     </div>
  *   </div>
  *   // optional supplementary content here
  * </div>
  *
  * Nested `div`s required for the aspect ratio stuff
  */
.ui-component-voucher {
  width: 100%; // required if setting margin: 0 auto;
  max-width: $voucher--max-card-width;
  min-width: sb-rem(300px);
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;

  > div:first-child {
    position: relative;
    height: 0;
    padding-bottom: $voucher--aspect-ratio; // https://css-tricks.com/aspect-ratio-boxes/
    background-color: #fff;
    border-radius: sb-rem(12px); // ID-1 rounded corners ~ 1/8"
    box-shadow: 0 1px 5px $black;
    overflow: hidden;

    > div {
      position: absolute;
      overflow: hidden; // so border-radius clips ::before
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $space-xs;
      color: #fff;
      background: -webkit-linear-gradient(right top, rgba($sb-green, .9) 20%, $sb-green);
      background: -moz-linear-gradient(right top, rgba($sb-green, .9) 20%, $sb-green);
      background: linear-gradient(right top, rgba($sb-green, .9) 20%, $sb-green);

      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: -webkit-linear-gradient(top, rgba(#fff, .15), transparent),
                    -webkit-linear-gradient(left top, transparent, rgba(#fff, .1) 33%, transparent 0%);
        background: -moz-linear-gradient(top, rgba(#fff, .15), transparent),
                    -moz-linear-gradient(left top, transparent, rgba(#fff, .1) 33%, transparent 0%);
        background: linear-gradient(top, rgba(#fff, .15), transparent),
                    linear-gradient(left top, transparent, rgba(#fff, .1) 33%, transparent 0%);
      }

      span {
        display: block;
        text-transform: uppercase;

        &:first-child { // "Gift voucher"
          position: absolute;
          top: $space-xs;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: sb-rem(3px);
        }

        &:last-child { // price/amount
          position: relative;
          width: 100%;
          font-size: $base-font * math.pow($scale, 5);
          font-weight: 400;
          line-height: 1;
        }

        &.utils\:wave::after {
          position: absolute;
          width: 100%;
          margin: 0;
        }
      }
    }

    + * {
      margin-top: $space-sm;
    }
  }

  &.size\:sm {
    min-width: sb-rem(112px);

    > div:first-child {
      border-radius: sb-rem(4px);
 
      > div {
        span {
          &:first-child {
            top: $space-3xs;
            letter-spacing: sb-rem(0.5px);
            font-size: sb-rem(6px);
          }
  
          &:last-child {
            font-size: $base-font * math.pow($scale, 1.5);
          }
  
          &.utils\:wave::after {
            top: 14px;
          }
        }
      }
    }
  }

  &.bg\:envelope {
    max-width: none;
    padding: $space calc(50% - #{$voucher--max-card-width} / 2);
    background-color: #fff;
    border: $ui-border;
    border-radius: $ui-border-radius;
    box-shadow: $ui-box-shadow;

    > div:first-child {
      padding-bottom: calc(#{$voucher--aspect-ratio} - #{$space});
      margin-right: calc(var(--space) * 2);

      > div {
        margin-left: $space;
      }
    }
  }
}
