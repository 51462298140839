@import "config/config";
@import "helpers/utilities";

/**
  * Markup:
  * <div class="ui-component-autocomplete">
  *   <label>
  *     <span>Input label</span>
  *     <input type="text" role="combobox" autocomplete="off" aria-autocomplete="list" aria-expanded={true,false} aria-owns="listbox-element-id">
  *   </label>
  *   <div>
  *     <{ul,ol} role="listbox" id="listbox-element-id">
  *       <li role="heading"></li> // optional heading for grouped results
  *       <li role="option" tabindex="-1" aria-selected={true,false}></li>
  *     </{ul,ol}>
  *     <div aria-live="polite" role="status">number of results for screenreaders here</div>
  *   </div>
  * </div>
  */
.ui-component-autocomplete {
  position: relative;
  display: flex;

  > div {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: $z-index-dropdown;
    background-color: #fff;
    border-radius: $ui-border-radius;
    box-shadow: $ui-box-shadow-heavy;

    [role="listbox"] {
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;

      li {
        padding: $space-3xs $space-xxs;
        cursor: pointer;
        @include user-select-none;

        &[aria-selected="true"] {
          background-color: $sb-green-100;
        }

        &[role="heading"] {
          margin: 0 $space-xxs;
          padding-left: 0;
          padding-right: 0;
          border-bottom: $ui-border;
          border-color: $grey-100;
          font-weight: 700;
          cursor: default;
        }

        &.divider:not(:last-of-type) {
          margin-bottom: $space;
          border-bottom: $ui-border;
        }
      }
    }

    [role="status"] {
      @include sr-only;
    }
  }
}
