.wrapper {
  max-width: sb-rem(1200px);
  margin: 0 auto;
  padding-right: sb-rem($spacing-unit--small);
  padding-left: sb-rem($spacing-unit--small);

  @include media-query("palm-and-up") {
    padding-right: sb-rem($spacing-unit);
    padding-left: sb-rem($spacing-unit);
  }
}

.wrapper--wider {
  max-width: 82.5rem;
}
