@import "config/config";
@import "helpers/utilities"; // sr-only

/**
  * Markup:
  * <fieldset class="ui-component-counter
  *                  theme:{primary*, premium}
  *                  variant:{room-counter}
  *                  --selected"> // Set via JS when counter > 0
  *   <button class="ui-component-button variant:counter">
  *     <span>Decrement</span>
  *   </button>
  *
  *   <label class="ui-component-label">
  *     <input type="text" size="4" readonly> // Remove `readonly` to allow manual input
  *   </label>
  *
  *   <button class="ui-component-button variant:counter">
  *     <span>Increment</span>
  *   </button>
  * </fieldset>
  */
.ui-component-counter {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 6px 2px 3px 2px; // Fix alignment issue with fa icons
  border-radius: 2em;
  background-color: #fff;

  input[type="text"] {
    width: 4ch;
    padding: 0;
    padding-bottom: 4px;
    background-color: inherit;
    border: transparent;
    box-shadow: none;
    text-align: center;
    font-size: 1rem;
    line-height: 1;

    &[readonly] {
      background: transparent;
      outline: none !important;
      pointer-events: none;
    }
  }

  &:not(:has(button:not([disabled]))) { // Both buttons disabled
    background-color: $grey-100;
    opacity: $ui-opacity-disabled;
  }

  &, &.theme\:primary, .theme\:primary & {
    border-color: $sb-green;

    &.--selected {
      background-color: $sb-green-100;
    }

    .ui-component-button.variant\:counter {
      flex: 0;

      &, &:hover, &:focus, &:active {
        color: $sb-green !important;
      }
    }
  }

  &.theme\:premium, .theme\:premium & {
    border-color: $sb-gold !important;

    &.--selected {
      background-color: $sb-gold-100;
    }

    .ui-component-button.variant\:counter {
      &, &:hover, &:focus, &:active {
        color: $sb-gold !important;
      }
    }
  }

  .ui-component-button.variant\:counter {
    padding: 0 !important;

    &, &:hover, &:focus, &:active {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    > span:first-child {
      @include sr-only;
    }
  }

  &.variant\:room-counter {
    position: relative;

    input[type="text"] {
      opacity: 0;
    }

    .input-label {
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 400;
    }
  }
}
