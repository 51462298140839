:root {
  --perfect-fourth: calc(4 / 3);

  --font-scale: var(--perfect-fourth);
  --base-font: 1rem;
  --base-line-height: 1.5;
  --font-lg: calc(var(--base-font) * var(--font-scale));

  --space-scale: var(--perfect-fourth);
  --space: 1.5rem;
  --space-lg: calc(var(--space) * var(--space-scale));
  --space-xl: calc(var(--space-lg) * var(--space-scale));
  --space-2xl: calc(var(--space-xl) * var(--space-scale));
  --space-3xl: calc(var(--space-2xl) * var(--space-scale));
  --space-sm: calc(var(--space) / var(--space-scale));
  --space-xs: calc(var(--space-sm) / var(--space-scale));
  --space-2xs: calc(var(--space-xs) / var(--space-scale));
  --space-3xs: calc(var(--space-2xs) / var(--space-scale));

  --screen-min: 25; // 25rem == 400px
  --screen-max: 80; // 80rem == 1280px
  --screen-diff: calc(var(--screen-max) - var(--screen-min));
  --viewport-diff: calc(100vw - var(--screen-min) * 1rem);
  --viewport-ratio: var(--viewport-diff) / var(--screen-diff);

  --blue: #2b62fe;
  --green: #008000;
  --purple: #4b0082;
  --red: #f42503;
  --yellow: #e49c02;
}
