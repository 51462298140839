@import "config/config";
@import "helpers/forms";
@import "helpers/media-queries";
@import "helpers/units";
@import "helpers/utilities";

/**
 * Markup:
 * <form class="ui-component-form
 *              variant:{...}
 *              layout:{wrap*, stacked}"
 *              justify:{left*, center}">
 *   <label class="ui-component-{input, checkbox, radio}"></label>
 *   <fieldset class="ui-component-{input, checkbox, radio}-group"></fieldset>
 * </form>
 *
 * Note: Form controls are full-width by default, use `width:{*}` class on
 * children to allow successive controls to share a row.
 */
.ui-component-form {
  --form-row-gap: var(--space);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--form-row-gap);
  margin-bottom: 0;
  text-align: left;

  a {
    text-decoration: underline; // @TODO: remove when global styles sorted out
  }

  > :not(:last-child) {
    margin-bottom: 0 !important;
  }

  > :not(button):not([type="submit"]) {
    flex-basis: 100%;
    @include forms--wrapped-widths;
  }

  &.layout\:stacked {
    flex-direction: column;
    flex-wrap: nowrap;

    > :not(button):not([type="submit"]) {
      @include forms--stacked-widths;
    }
  }

  &.justify\:center {
    justify-content: center;
  }

  &.variant\:payment {
    [data-flex-microform-input] {
      @include forms--input-appearance;

      > iframe {
        max-height: 24px;
        margin-bottom: -6px;
      }
    }

    [data-flex-microform-input="security-code"] {
      @include phablet-up {
        @include forms--input-width(4);
      }
    }

    img { // secure payment logo
      width: sb-rem(150px) !important;
      flex-basis: 0;
    }

    ~ [data-flex-microform-iframe] {
      display: block;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      border: none;
    }
  }

  &.variant\:search {
    $form--search-min-input: 200px; // can't use rem because body font-size: 14px on small screens

    gap: $space-xs;
    align-items: center;
    margin: 0;
    padding: $space;
    background-color: #fff;
    border-radius: $ui-border-radius-lg;
    box-shadow: $ui-box-shadow-heavy;

    > * {
      margin: 0 !important;
      flex-basis: 100%;
    }

    .search-location {
      flex: 1 1 800px;

      @media screen and (min-width: 944px) {
        flex-basis: 300px;
      }

      @media screen and (min-width: 1244px) {
        flex-basis: 600px;
      }
    }

    @include phablet-up {
      > :not(button) {
        flex: 1 1 $form--search-min-input;
      }

      > button {
        flex-basis: auto;
        flex-grow: 1;
      }
    }
  }
}
