@import "../helpers/backgrounds";

.pagination {
  margin: $space-lg 0;

  > div {
    display: flex;
    gap: $space-xs;
    align-items: baseline;
    justify-content: center;
  }

  a {
    padding: 0 $space-xxs;
    color: $sb-green;
  }

  :is(.first, .prev, .next, .last) a {
    padding: 0;
    white-space: nowrap;
  }

  .current {
    color: #fff;
    @include circle-bg($spacing-unit, $green);
  }
}

