.reviews__criteria {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: sb-rem($spacing-unit);
  margin-bottom: sb-rem($spacing-unit);
  padding-left: 0;
  list-style-type: none;
  transition: max-height 0.3s cubic-bezier(1,0,1,0);
}

.reviews__criteria-item {
  font-size: 14px;

  > :first-child {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.reviews__criteria-item-bar {
  background-color: $sb-green-100;
  border: 1px solid $sb-green-100;
  border-radius: 15px;
  height: 15px;
  position: relative;
  overflow: hidden;

  .page--premium & {
    background-color: $sb-gold-100;
    border: 1px solid $sb-gold-100;
  }
}

.reviews__criteria-item-bar-background {
  background-color: $sb-green-200;
  border: 1px solid $sb-green-200;
  border-radius: 15px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 1.5s ease-in-out;
  transform: translateX(-99%);

  .page--premium & {
    background-color: $sb-gold;
    border: 1px solid $sb-gold;
  }
}

.reviews__filter {
  border: 1px solid $grey--lighter;
  color: $black;
  padding: sb-rem($spacing-unit--small);

  > :first-child {
    margin-bottom: sb-rem($spacing-unit--small);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: .8;
  }
}

.reviews__filter-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: sb-rem($spacing-unit--small);
}

.reviews__item {
  display: flex;
  flex-direction: column;
  padding: sb-rem($spacing-unit) 0;

  &:not(:first-child) {
    border-top: 1px solid $grey--lighter;
  }

  @include media-query(desk) {
    flex-direction: row;
  }
}

.reviews__item-rating-content {
  border: 1px solid $sb-green;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: sb-rem($spacing-unit--tiny);
  margin-bottom: sb-rem($spacing-unit--small);
  width: 100%;

  .page--premium & {
    border-color: $sb-gold;
  }

  @include media-query(desk) {
    display: block;
    padding: sb-rem($spacing-unit) sb-rem($spacing-unit--small);
    text-align: center;
    width: 120px;
  }
}

.reviews__item-rating-numeric {
  font-size: 24px;
  line-height: 100%;
  color: $sb-green;
  padding: 0 sb-rem($spacing-unit--tiny);
  text-align: center;

  .page--premium & {
    color: $sb-gold;
  }

  @include media-query(desk) {
    font-size: 40px;
    width: 100%;
  }
}

.reviews__item-rating-text {
  font-size: 16px;
  line-height: 120%;
  padding: 0 sb-rem($spacing-unit--tiny);
}

.reviews__item-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 100%;

  @include media-query(desk) {
    flex: 95%;
    padding-left: sb-rem($spacing-unit);
  }
}

.reviews__item-show-more {
  text-align: left;
  margin-bottom: em($spacing-unit--tiny);
}

.reviews__item-meta {
  text-align: right;
}

.reviews__item-meta-author {
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%;
}

.reviews__no-results {
  text-align: center;

  &.is-visible {
    display: block;
  }
}

.reviews__show-more {
  display: inline-block;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.reviews__venue-rating {
  display: inline-block;
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: $ui-border-radius;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  background-color: $black;

  &.high {
    background-color: $sb-green;
  }

  &.average {
    background-color: $sb-green-300;
  }
}
