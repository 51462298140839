@import "../ui_components/helpers/media-queries";
@import "../ui_components/helpers/utilities"; // text-small

.site-footer {
  padding: var(--space) 0;
  background: $grey-100;
  color: $black;

  .layout__item > .layout {
    row-gap: var(--space-xl);
  }

  a {
    text-decoration: none !important;
  }

  nav {
    .layout__item > .layout {
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: var(--space-xs);
    }
  }

  small {
    display: block;
  }

  @include tablet-portrait-up {
    .site-logo {
      margin: 0 auto var(--space) auto;
    }

    .site-footer__social,
    .site-footer__subnav {
      justify-content: center;
    }

    small {
      text-align: center;
    }
  }
}

.site-footer__our-spas {
  display: block !important;

  @include tablet-portrait-up {
    columns: 2;
    column-gap: var(--space);
  }

  > li {
    margin-bottom: var(--space-xs);
  }
}

.site-footer__opening-hours {
  margin-bottom: var(--space);
  margin-top: var(--space-xl);
  padding-top: var(--space-xl);
  border-top: $ui-border;

  @media screen and (min-width: 801px) {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--space-xs);
    margin: 0;

    + h4 {
      padding-top: var(--space-sm);
      border-top: 1px solid $sb-green;
    }

    > dt {
      font-weight: normal;
    }

    > dd {
      margin: 0;
      text-align: right;
    }

    + p {
      margin-top: var(--space);
    }
  }
}

.site-footer__social {
  display: flex;
  column-gap: var(--space-xs);
  margin: var(--space-xl) 0 var(--space) 0 !important;
  padding-top: var(--space-xl) !important;
  border-top: $ui-border;
}

.site-footer__subnav {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-xs);
  @include text-small;

  button {
    text-decoration: none !important;
  }
}
