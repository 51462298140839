@import "../helpers/truncate";
@import "../../ui_components/helpers/utilities";

.search-filters--loading {
  opacity: .5;
}

.filter-panel {
  @include container-style("--screen-desktop-search", false) {
    display: none;
  }

  @include media-query(desk) {
    box-shadow: none !important;
  }

  @include media-query(portable) {
    background: #fff;
    padding: sb-rem($spacing-unit);
    margin: sb-rem($spacing-unit) 0;
  }

  .ui-component-modal & {
    display: block;
  }
}

.filter-panel__heading {
  display: flex;
  justify-content: space-between;
  margin-top: sb-rem($spacing-unit);
  margin-bottom: sb-rem($spacing-unit--small);
  padding: sb-rem($spacing-unit--tiny);
  border-top: 1px solid $grey--lighter;
  border-bottom: 1px solid $grey--lighter;
  font-weight: 700;

  > a {
    font-weight: 400;
    color: $color-text;
    text-decoration: underline;
  }
}
