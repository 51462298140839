@import "config/config";

.ui-component-box {
  --box-space: var(--space);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--box-space);
  padding: var(--box-space);
  text-align: left;
  color: $black;
  background-color: #fff;
  border-radius: $ui-border-radius-lg;
  box-shadow: $ui-box-shadow-heavy;

  > * {
    width: 100%;
    margin-bottom: 0;
  }

  &.variant\:border {
    box-shadow: none;
    border: $ui-border;
  }

  &.size\:sm {
    --box-space: var(--space-sm);
  }
}
