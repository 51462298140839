@import "config/config";

/**
  * Markup:
  * <div class="ui-component-quick-links">
  *   <div><{h*}></{h*}></div> // heading
  *   <{ul,ol}></{ul,ol}> // list of links
  * </div>
  */
.ui-component-quick-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden; // for border-radius clipping
  border-radius: $ui-border-radius-lg;
  box-shadow: $ui-box-shadow-heavy;
  background-color: #fff;

  > :first-child {
    flex-basis: 40%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: $space;
    color: #fff;
    background-color: $sb-green;
    text-align: center;

    > * {
      width: 100%;
      margin: 0;
    }
  }

  > :last-child {
    --gap: #{$space-sm};
    flex-basis: 60%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(180px, calc(100% - var(--gap)) / 2), 1fr));
    gap: var(--gap);
    align-items: center;
    margin: 0;
    padding: $space;
    list-style-type: none;
    text-align: center;

    li {
      --gap: #{$space-xxs};
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(max(150px, calc(100% - var(--gap)) / 2), 1fr));
      gap: var(--gap);
      align-items: center;
      font-weight: 700;

      > * {
        margin: 0;
      }
    }
  }
}
