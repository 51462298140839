@use "sass:math";

@import "config/config";
@import "helpers/media-queries";
@import "helpers/wrapper";

/**
 * Markup:
 * <section class="ui-component-banner
 *                 variant:{*}
 *                 width:{sm, md*, lg}
 *                 align:{left, center}">
 *   <picture></picture>
 *   <article class="mixin:clamped"></article>
 *   <a>{caption}</a> // optional
 * </section>
 */
.ui-component-banner {
  position: relative; // for absolute `picture` and/or caption
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: min(50vw, 480px);
  padding: 0 !important;
  box-shadow: inset 0 40px 40px -40px rgba(#000, .4),
              inset 0 -40px 40px -40px rgba(#000, .4);

  > picture {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:not(:only-child) {
      z-index: -1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    @include wrapper-with-padding;

    &.mixin\:clamped {
      --mixin-clamped-min: var(--space-scale) * var(--space-scale) * var(--space-scale);
      --mixin-clamped-max: var(--mixin-clamped-min) * var(--space-scale);

      padding-top: var(--mixin-clamped);
      padding-bottom: var(--mixin-clamped);
    }

    > header {
      :first-child {
        font-weight: 700;
      }

      :last-child {
        margin-bottom: $space;
      }
    }

    h1, h2, h3, h4, p {
      &:last-child {
        margin-bottom: 0;
      }

      &, a {
        color: #fff;
        text-shadow: 0px 0px 5px #000;
      }
    }
  }

  &.align\:center article {
    right: 0;
    bottom: 0;
    align-items: center;
    text-align: center;
  }

  &.align\:left article {
    align-items: flex-start;
    text-align: left;
  }

  &.width\:sm article {
    @include wrapper-with-padding(sm);
  }

  &.width\:lg article {
    @include wrapper-with-padding(lg);
  }

  // Caption
  > a {
    display: none;

    @include mq(tablet-landscape-up) {
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
      margin: auto var(--space-2xs) var(--space-2xs) 0;
      font-size: .95rem;
      font-style: italic;
      color: #fff;
      text-decoration: underline;
    }
  }

  &.variant\:home {
    --gradient-box-height: 150px;

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    box-shadow: inset 0 -300px 40px -40px rgba(#000, .25);

    .--autocomplete-open &,
    .--datepicker-open & {
      z-index: #{$z-index-sticky + 1}; // above navbar
    }

    > picture {
      @include tablet-portrait-max {
        top: var(--gradient-box-height);
      }
    }

    > article {
      > header {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 0;

        :first-child {
          text-transform: uppercase;
        }

        :last-child {
          margin-bottom: $space;
        }
      }

      > .ui-component-button {
        width: auto !important;
        text-transform: none;
        letter-spacing: normal;
        color: $black;
        border-color: #fff;

        &, &:hover, &:focus {
          background-color: #fff;
        }
      }
    }

    // Search form
    > aside {
      padding-top: var(--space-sm);

      @include tablet-portrait-max {
        position: relative;
        padding-left: var(--space-sm);
        padding-right: var(--space-sm);

        &::before {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: var(--gradient-box-height);
          background: linear-gradient(0deg, #3a4f6c 5%, #4fa296 100%);
          z-index: -1;
          left: 0;
          top: 0;
        }
      }

      h1 {
        flex-basis: 100%;
        text-align: center;
        color: $grey-400;

        @include container-style("--screen-desktop-search", true) {
          font-size: var(--base-font);
        }
      }
    }
  }
}
