@import "config/config";
@import "helpers/media-queries"; // breakpoint

/**
  * Markup:
  * <div class="ui-component-hide-and-seek"
  *             variant:{search}
  *      data-hide-and-seek={top*, bottom}>
  *   // header or nav etc. here
  * </div>
  */
.ui-component-hide-and-seek {
  --box-shadow-bottom: 0 4px 6px #{$black}1A; // 1A hex ~ 20% transparency
  --box-shadow-top: 0 -4px 6px #{$black}1A; // 1A hex ~ 20% transparency

  position: sticky;
  left: 0;
  right: 0;
  display: block;
  z-index: $z-index-sticky;

  .--modal-open &:has(.ui-component-modal:not([hidden])) {
    z-index: $z-index-modal;
  }

  body:not(.--map-open) & {
    transition: transform .4s;
  }

  &:not([data-hide-and-seek="bottom"]) {
    top: 0;

    &.is-sticky {
      box-shadow: var(--box-shadow-bottom);

      body.scrolling-down & {
        transform: translate3d(0, -100%, 0);
        box-shadow: none;
      }

      body.scrolling-up & {
        transform: none;
      }
    }
  }

  &[data-hide-and-seek="bottom"] {
    bottom: 0;

    &.is-sticky {
      box-shadow: var(--box-shadow-top);

      body.scrolling-down & {
        transform: none;
      }

      body.scrolling-up & {
        transform: translate3d(0, 100%, 0);
        box-shadow: none;
      }
    }
  }

  &.variant\:search {
    body:not(.--modal-open) & {
      // Delay z-index for modal close animation
      transition: transform .4s z-index 0s 0.2s;
    }

    // Keep sticky and don't hide when map open
    body.--map-open & {
      transform: none !important;
      box-shadow: var(--box-shadow-bottom) !important;
    }

    // Ensure filters modal on top when map open
    body.--map-open.--modal-open &:has(.ui-component-modal[hidden]) {
      z-index: 0;
      transition: none;
    }
  }
}
