// Apply to pseudo-elements to extend element background beyond max-width of parent
@mixin background-extend {
  display: block;
  content: "";
  position: absolute;
  z-index: -1;
  left: calc(50% - 50vw);
  top: 0;
  bottom: 0;
  width: 100vw;
}
