@import "../config/config";

$wrapper--widths: (
  sm: 67.5rem, // 1200 * 0.9
  md: 75rem, // 1200
  lg: 82.5rem // 1200 * 1.1
);

@mixin wrapper($width: md, $center: true) {
  max-width: map-get($wrapper--widths, $width);

  @if $center == true {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin wrapper-with-padding($width: md, $center: true) {
  --_space-xs: var(--space-scale) / var(--space-scale) / var(--space-scale);
  --_space: var(--space-scale);

  padding-left: clamp(
    var(--space-xs),
    var(--space-xs) + (var(--_space) - var(--_space-xs)) * var(--viewport-ratio),
    var(--space)
  );
  padding-right: clamp(
    var(--space-xs),
    var(--space-xs) + (var(--_space) - var(--_space-xs)) * var(--viewport-ratio),
    var(--space)
  );
  @include wrapper($width, $center);
}
