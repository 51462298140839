@mixin unstyled-link() {
  transition: all 0s;

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
    color: inherit !important;
    border-bottom-width: 0 !important;
    background-color: transparent !important;
  }
}

