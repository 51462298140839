@import "config/config";

/**
 * Markup:
 * <div class="ui-component-newsletter">
 *   <div class="ui-component-feature"></div>
 *   <form class="ui-component-form"></form>
 * </div>
 */
.ui-component-newsletter {
  padding: $space-lg;
  border: $ui-border;
  border-radius: $ui-border-radius;
  text-align: center;

  .ui-component-form .ui-component-button {
    width: 100% !important;
  }
}
