/**
  * Markup:
  * <div class="utils:{wave, loop, swirl-1, swirl-2}
  *             swirl-1:{modifier} loop:{modifier}
  *             stack:{above*, behind}">
  * </div>
  */
.utils\:wave,
[class*="utils:swirl-"],
[class*="utils:loop"] {
  position: relative;

  &.stack\:behind {
    z-index: 0;

    &::before,
    &::after {
      z-index: -1;
    }
  }
}

.utils\:wave::after,
.utils\:swirl-1::before,
.utils\:swirl-2::after,
.utils\:loop::after {
  display: block;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  pointer-events: none;
}

.utils\:wave::after {
  position: relative;
  background-image: url(../images/wave.svg);
  background-size: contain;
  background-position: 50% 50%;
  width: 230px;
  max-width: 100%;
  height: 30px;
  margin: $space-xxs auto;
}

.utils\:swirl-1::before,
.utils\:swirl-2::after {
  background-image: url(../images/swirl-green.svg);
  width: 100px;
  height: 100px;
}

.utils\:swirl-1.swirl-1\:sm::before,
.utils\:swirl-2.swirl-2\:sm::after {
  background-image: url(../images/swirl-green-sm.svg);
  width: 75px;
  height: 75px;
}

.utils\:swirl-1.swirl-1\:xs::before,
.utils\:swirl-2.swirl-2\:xs::after {
  background-image: url(../images/swirl-xs.svg);
  width: 50px;
  height: 50px;
}

.utils\:swirl-1.swirl-1\:xl::before,
.utils\:swirl-2.swirl-2\:xl::after {
  width: 200px;
  height: 200px;
}

.utils\:swirl-1.swirl-1\:flip::before,
.utils\:swirl-2.swirl-2\:flip::after {
  transform: scaleY(-1);
}

.utils\:swirl-1.swirl-1\:top-left::before,
.utils\:swirl-2.swirl-2\:top-left::after {
  top: calc(-1 * var(--space));
  left: $space;
}

.utils\:swirl-1.swirl-1\:top-right::before,
.utils\:swirl-2.swirl-2\:top-right::after {
  top: calc(-1 * var(--space));
  right: $space;
}

.utils\:swirl-1.swirl-1\:bottom::before,
.utils\:swirl-2.swirl-2\:bottom::after {
  bottom: 0;
  right: $space-xs;
}

.utils\:swirl-1.swirl-1\:bottom-left::before,
.utils\:swirl-2.swirl-2\:bottom-left::after {
  bottom: calc(-1 * var(--space-sm));
  left: $space;
}

.utils\:swirl-1.swirl-1\:bottom-right::before,
.utils\:swirl-2.swirl-2\:bottom-right::after {
  bottom: calc(-1 * var(--space-sm));
  right: $space;
}

.utils\:loop::after {
  background-image: url(../images/loop-green.svg);
  width: 400px;
  height: 130px;
}

.utils\:loop.loop\:sm::after {
  background-image: url(../images/loop-small.svg);
  width: 170px;
  height: 50px;
}

.utils\:loop.loop\:lg::after {
  width: 800px;
  height: 260px;
}

.utils\:loop.loop\:top {
  &::after {
    top: 0;
    left: 0;
    transform: translateX(25%);
  }

  &.loop\:flip::after {
    transform: translateX(25%) scaleY(-1);
  }
}

.utils\:loop.loop\:top-left {
  &::after {
    top: calc(-1 * var(--space));
    left: 0;
    transform: translateX(-50%);
  }

  &.loop\:flip::after {
    transform: translateX(-50%) scaleY(-1);
  }
}

.utils\:loop.loop\:top-right::after {
  top: calc(-1 * var(--space));
  right: $space;
}

.utils\:loop.loop\:bottom-left {
  &::after {
    bottom: $space;
    left: 0;
    transform: translateX(-50%);
  }

  &.loop\:flip::after {
    transform: translateX(-50%) scaleY(-1);
  }
}
