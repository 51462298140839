$container-style-map: (
  "--float-marker-content": ( true: "(max-width: 500px)", false: "(min-width: 501px)" ),

  // See webpack/src/components/elm/Utils/CssConstants.elm
  "--screen-desktop-search": ( true: "(min-width: 1025px)", false: "(max-width: 1024px)" )
);

@function container-style-condition($name, $value) {
  @return map-get(map-get($container-style-map, $name), $value);
}

@mixin container-style($name, $value) {
  @media screen and #{container-style-condition($name, $value)} {
    @content;
  }
}

$_mq-breakpoints: (
  mobile-up: 360px,
  mobile: 360px 479px,
  phablet-up: 480px,
  phablet: 480px 767px,
  tablet-portrait-up: 768px, // See webpack/src/components/elm/Utils/CssConstants.elm
  tablet-portrait: 768px 899px,
  tablet-landscape-up: 900px,
  tablet-landscape: 900px 1199px,
  desktop: 1200px,
  widescreen: 1800px
);

@mixin _media($breakpoint, $max: false) {
  $break: map-get($_mq-breakpoints, $breakpoint);

  @if type-of($break) == list {
    @media screen and (min-width: nth($break, 1)) and (max-width: nth($break, 2)) {
      @content;
    }
  } @else {
    @if $max {
      @media screen and (max-width: $break - 1px) {
        @content;
      }
    } @else {
      @media screen and (min-width: $break) {
        @content;
      }
    }
  }
}

@function breakpoint($breakpoint) {
  $break: map-get($_mq-breakpoints, $breakpoint);

  @if type-of($break) == list {
    @return nth($break, 1);
  } @else {
    @return $break;
  }
}

@mixin mq($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @include _media($breakpoint) { @content };
  }
}

@mixin mobile-up {
  @include _media(mobile-up) { @content; };
}

@mixin mobile {
  @include _media(mobile) { @content; };
}

@mixin mobile-max {
  @include _media(phablet-up, $max: true) { @content; };
}

@mixin phablet-up {
  @include _media(phablet-up) { @content; };
}

@mixin phablet {
  @include _media(phablet) { @content; };
}

@mixin tablet-portrait-up {
  @include _media(tablet-portrait-up) { @content; };
}

@mixin tablet-portrait {
  @include _media(tablet-portrait) { @content; };
}

@mixin tablet-portrait-max {
  @include _media(tablet-portrait-up, $max: true) { @content; };
}

@mixin tablet-landscape-up {
  @include _media(tablet-landscape-up) { @content; };
}

@mixin tablet-landscape {
  @include _media(tablet-landscape) { @content; };
}

@mixin desktop {
  @include _media(desktop) { @content; };
}

@mixin desktop-max {
  @include _media(desktop, $max: true) { @content; };
}

@mixin widescreen {
  @include _media(widescreen) { @content; };
}
