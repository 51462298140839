@import "config/config";
@import "helpers/forms";
@import "helpers/units";
@import "helpers/utilities";

@mixin control-group--inline {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-sm);

  > label {
    flex-shrink: 0;
  }

  &.width\:full > label {
    flex-grow: 1;
  }

  label {
    margin-bottom: 0;
  }
}

/**
 * Markup:
 * <fieldset class="ui-component-{checkbox, radio}-group
 *                  variant:{default*, inline, inline-always}
 *                  width:{auto*, full}
 *                  radio:{bordered, pill, button, outline}">
 *   <legend>Fieldset caption</legend> // optional, or use .sr-only
 *   <label class="ui-component-{checkbox, radio-button}">
 *     ...
 *   </label>
 * </fieldset>
 */
.ui-component-checkbox-group,
.ui-component-radio-group {
  @include forms--fieldset-appearance;
  display: flex;
  flex-direction: column;
  margin-bottom: $space-sm;
  gap: $space-sm; // more spacing for easier selection on mobile

  &:last-child {
    margin-bottom: 0;
  }

  @include desktop {
    gap: $space-3xs;
  }

  legend {
    @include forms--legend-appearance;

    &:not(.sr-only) ~ label {
      font-weight: 400; // use normal font-weight for labels if legend visible
    }
  }

  &.variant\:inline {
    @include phablet-up {
      @include control-group--inline;
    }
  }

  &.variant\:inline-always {
    @include control-group--inline;
  }

  &.variant\:stretch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      flex: 1;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      &.radio\:pill [type="radio"] + span {
        justify-content: flex-start;
      }
    }
  }

  &.radio\:bordered {
    gap: $space-xs !important;

    label {
      align-self: stretch;

      [type="radio"] {
        + span {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: $space-xxs $space-sm;
          border: $ui-border;
          border-radius: $ui-border-radius;
        }

        &:checked + span {
          border-color: $sb-green;
        }
      }
    }
  }

  &.radio\:pill,
  &.radio\:button,
  &.radio\:outline {
    gap: var(--space-2xs) !important;
    justify-content: flex-start;
    flex-wrap: wrap;

    [type="radio"] {
      + span {
        &::before {
          display: none;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: var(--space-3xs) var(--space-sm);
        border: $ui-border;
        border-radius: 1.25rem;
      }

      &:checked + span {
        border-color: $sb-green;
        background-color: $sb-green-100;
        color: $sb-green;

        .theme\:premium & {
          border-color: $sb-gold;
          background-color: $sb-gold-100;
          color: $sb-gold;
        }
      }
    }
  }

  &.radio\:button [type="radio"] + span {
    border-radius: 4px;
  }

  &.radio\:outline [type="radio"]:checked + span {
    background-color: $grey-100 !important;
    color: $black !important;
  }
}
