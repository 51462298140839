@use "sass:color";

@import "../../helpers/hero";

$hero-gradient-pos: 25%;
$hero-gradient-pos-center: 50%;
$hero-gradient-opacity: .4;
$hero-gradient-opacity-dark: .6;
$hero-gradient-opacity-light: .2;

.hero-banner-wrapper {
  display: flex; // IE11 bug https://github.com/philipwalton/flexbugs#flexbug-3
  max-width: sb-rem(1920px);
  margin: 0 auto;
}

.hero-banner {
  display: flex;
  width: 100%;
  min-height: sb-rem(100px);
  margin-bottom: sb-rem($spacing-unit);
  text-shadow: 0px 0px 5px #000;
  box-shadow: inset 0 em(40px) em(40px) em(-40px) rgba(#000, .4),
              inset 0 em(-40px) em(40px) em(-40px) rgba(#000, .4);
  background-position: 50% 50%;
  background-size: cover;

  @include media-query("palm-and-up") {
    min-height: sb-rem(400px);
  }
}

.hero-banner--center {
  text-align: center;

  .lead {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }
}

.hero-banner__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: sb-rem($spacing-unit);
  padding-bottom: sb-rem($spacing-unit);
  color: #fff;
  @include hero-gradient($hero-gradient-pos, $hero-gradient-opacity);

  picture + & {
    position: absolute;
    height: 100%;
    cursor: pointer;
  }

  & > div {
    width: 100%;
  }

  h1, h2 {
    font-weight: 700;
  }

  p:last-child {
    margin-bottom: 0;
  }

  p a {
    @extend .link--alt;
    border-bottom: 1px solid;

    &:hover,
    &:focus,
    &:active {
      color: color.adjust($color-links--alt, $lightness: -10%);
    }
  }

  .hero-banner--center & {
    @include hero-gradient($hero-gradient-pos-center, $hero-gradient-opacity);
  }
}

.hero-banner__title,
.hero-banner__subtitle {
  font-weight: bold;
}
