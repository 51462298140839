// Defaults
// https://github.com/inuitcss/settings.defaults/blob/master/_settings.defaults.scss
@use "sass:math";

$inuit-base-font-size: 16px;
$inuit-base-line-height: round($inuit-base-font-size * 1.333 * 1.333);
$inuit-base-spacing-unit: $inuit-base-line-height;
$inuit-base-spacing-unit--tiny: round($inuit-base-spacing-unit * 0.25);
$inuit-base-spacing-unit--small: round($inuit-base-spacing-unit * 0.5);
$inuit-base-spacing-unit--large: round($inuit-base-spacing-unit * 2);
$inuit-base-spacing-unit--huge: round($inuit-base-spacing-unit * 4);

// Responsive settings
// https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
$breakpoints: (
  "small-palm"    "screen and (max-width: 480px)",
  "palm"          "screen and (min-width: 481px) and (max-width: 600px)",
  "palm-and-up"   "screen and (min-width: 481px)",
  "mobile"        "screen and (max-width: 600px)",
  "tab"           "screen and (min-width: 601px) and (max-width: 800px)",
  "tab-and-up"    "screen and (min-width: 601px)",
  "handheld"      "screen and (max-width: 800px)",
  "lap"           "screen and (min-width: 801px) and (max-width: 1024px)",
  "lap-and-up"    "screen and (min-width: 801px)",
  "portable"      "screen and (max-width: 1024px)",
  "desk"          "screen and (min-width: 1025px)",
  "retina"        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)"
);

$inuit-responsive-settings: true;

// Media query
// https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
@mixin media-query($mq) {
  @each $breakpoint in $breakpoints {
    $alias:     nth($breakpoint, 1);
    $condition: nth($breakpoint, 2);

    @if $mq == $alias and $condition {
      @media #{$condition} {
        @content;
      }
    }
  }
}

// Widths
// https://github.com/inuitcss/trumps.widths/blob/master/_trumps.widths.scss
$inuit-widths-columns: (1, 2, 3, 4, 5, 6, 12);

@mixin inuit-widths($inuit-widths-columns, $inuit-widths-breakpoint: null) {
  @each $inuit-widths-denominator in $inuit-widths-columns {
    @if ($inuit-widths-denominator == 1) {
      .u-1\/1#{$inuit-widths-breakpoint} {
        width: 100% !important;
      }
    } @else {
      @for $inuit-widths-numerator from 1 to $inuit-widths-denominator {
        .u-#{$inuit-widths-numerator}\/#{$inuit-widths-denominator}#{$inuit-widths-breakpoint} {
          width: math.div($inuit-widths-numerator, $inuit-widths-denominator) * 100% !important;
        }
      }
    }
  }
}

@include inuit-widths($inuit-widths-columns);

// Responsive widths
// https://github.com/inuitcss/trumps.widths-responsive/blob/master/_trumps.widths-responsive.scss
$inuit-widths-columns-responsive: (1, 2, 3, 4, 5, 6, 12);

@each $breakpoint in $breakpoints {
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {
    @each $inuit-widths-column in $inuit-widths-columns-responsive {
      @include inuit-widths($inuit-widths-column, -#{$alias});
    }
  }
}
